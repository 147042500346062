import styles from './SocialLinksGroup.module.scss';
import SocialLink from '../SocialLink/SocialLink';
import {
  faXTwitter,
  faYoutube,
  faInstagram,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import { SOCIAL_MEDIA_URLS } from '../../constants';

// TODO: CHECK SOCIAL MEDIA URLS, MAKE SURE THEY ARE CORRECT
// SO FAR WE DONT HAVE THE INSTAGRAM URL

export default function SocialLinksGroup() {
  return (
    <div className={styles.socialsContainer}>
      <SocialLink
        link={SOCIAL_MEDIA_URLS.TWITTER}
        icon={faXTwitter}
        name="twitter"
      />
      <SocialLink
        link={SOCIAL_MEDIA_URLS.FACEBOOK}
        icon={faFacebookF}
        name="facebook"
      />
      <SocialLink
        link={SOCIAL_MEDIA_URLS.YOUTUBE}
        icon={faYoutube}
        name="youtube"
      />
      <SocialLink
        link={SOCIAL_MEDIA_URLS.INSTAGRAM}
        icon={faInstagram}
        name="instagram"
      />
    </div>
  );
}
