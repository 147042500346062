import React from 'react';
import styles from './PrivacyPolicy.module.scss';

/**
 * PrivacyPolicy is a React functional component that renders an HTML elements
 * for the privacy policy modal of Ticketwindow Inc.
 *
 * @return {JSX.Element} The rendered privacy policy component.
 */
const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <h3>Privacy and Security Policy</h3>
      <p>
        Disclosure of Personal Information: Ticketwindow Inc. is committed to
        keeping your personal information strictly confidential. Only the
        organization of the performance for which tickets were purchased will
        retain your information. When we collect your personal information (i.e.
        name, address, e-mail, and telephone number) it is in an effort to
        improve your shopping experience and to notify you about program
        updates, services and answer inquiries. Beyond the presenting
        organization for your event it is our policy not to disclose any of your
        personal information at any time, unless you tell us to do so. We do not
        sell, trade or rent your personal information to third parties. From
        time to time, we’ll share aggregated (collective) information about our
        members as a group, or generate aggregate reports and market research
        for business partners. These reports will not identify individual
        members.
      </p>
      <p>
        Cookies: Our site uses cookies to personalize and improve your shopping
        experience. These cookies do not contain any confidential or personal
        information. They allow us to enhance your shopping experience at
        Ticketwindow Inc. For example, if you have added products to your
        shopping cart and suddenly have to log out of our site, you can return
        later that day and still have those items in your shopping cart.
      </p>
      <p>
        Security: We use industry-standard methods to protect your information
        from unauthorized access. Among other techniques, we store such
        information on a computer behind our “firewall” in a secure location,
        and we restrict the number of employees internally who can access such
        data. To ensure the safety of your personal information, including your
        credit card number, we use Secure Socket Layer (SSL) Technology. SSL
        protects information as it crosses the Internet . Any data stored is
        kept in a secure off-site location and stored in a password-protected
        server. Please be aware, of course, that there is no such thing as
        perfect security - on or off the Internet. Please keep security in mind
        as you reach other websites through links from Ticketwindow Inc. Each
        separate company has its own privacy policies and practices and may
        market to you accordingly. Although we choose our business partners
        carefully, we can make no representations concerning privacy and
        security on those sites. Your password is your key to your account. You
        should never share it with anyone and we request that you change it
        periodically and often. You can change your password at any time by
        accessing My Account on our home page. If you can’t remember your
        password, the reminder phrase you entered when you last changed or
        set-up your password will be e-mailed to you immediately. You may also
        contact us at sales@ticketwindow.ca for additional help. We strongly
        urge you to sign off your account and close your browser window when you
        have finished your shopping to prevent someone else from accessing your
        account. Changing Personal Information: You may at any time modify or
        change any information you have previously provided to us by clicking on
        “My Account”, then choose the appropriate section to edit, review, or
        delete your personal information. Changes will be updated immediately.
        You may also contact us at sales@ticketwindow.ca and we will be happy to
        change, modify, or delete any information for you. Changes in Policy: If
        you have any concerns or questions with respect to our Privacy Policy,
        please feel free to contact us at sales@ticketwindow.ca. By using this
        site, you are agreeing to the conditions of this Privacy Policy. DO NOT
        USE THIS SITE IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY. If we decide
        to change our Privacy Policy in whole or in part it will be posted on
        the site. Please check this page periodically for changes.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
