import React, { useState } from 'react';
import { Container, Row, Col, Nav, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialLink from '../../components/SocialLink/SocialLink';
import {
  faEnvelope,
  faLocationDot,
  faPhoneVolume,
} from '@fortawesome/free-solid-svg-icons';
import SocialLinksGroup from '../../components/SocialLinksGroup/SocialLinksGroup';
import { openExternalLinkInPopupWindow } from '../../utils/CommonUtils';
import styles from './ContactUs.module.scss';
import FormInput from '../../components/FormInput/FormInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { sendContactMessage } from '../../utils/APIUtils';

/**
 * ContactUs component.
 *
 * @return {JSX.Element} The ContactUs component.
 */
export default function ContactUs() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedRadio, setSelectedRadio] = useState('');
  /**
   * Opens a social media link in a popup window.
   *
   * @param {string} url - The URL of the social media link.
   * @param {string} name - The name of the social media platform.
   * @return {void} This function does not return anything.
   */
  const openSocialMediaInPopup = (url, name) => {
    openExternalLinkInPopupWindow(url, name, null);
  };

  const resetFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
    setPhone('');
    setSelectedRadio('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO: Implement submission validation
    const contactInfo = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      message: message,
      phone: phone,
      subject: selectedRadio,
    };
    console.log('contactInfo', contactInfo);
    try {
      const response = await sendContactMessage(contactInfo);
      console.log(response);
      if (response.status === 200) {
        resetFields();
      } else {
        //Show message error
        //TODO: Implement error handling
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.contactUs}>
      <div className={styles.titleContainer}>
        <h2>Contact Us</h2>
        <p>Any questions or remarks? Just write us a message!</p>
      </div>
      <div className={styles.contactContainer}>
        <div className={styles.contactCard}>
          <h3>Contact Information</h3>
          <p>Say something to start a live chat!</p>
          <div className={styles.infoContainer}>
            {/* TODO: CHANGE FOR REAL CONTACT INFO */}
            <a href="tel:+16471231234">
              <FontAwesomeIcon icon={faPhoneVolume} />
              <span>+1 647 123 1234</span>
            </a>
            <a href="mailto:demo@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} />
              <span>demo@gmail.com</span>
            </a>
            <a
              href="https://www.google.ca/maps/place/132+Queen+St+W,+Toronto,+ON+M5H+2N5"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLocationDot} />
              <span className={styles.address}>
                132 Queen Street, Toronto 02156 Canada
              </span>
            </a>
          </div>
          <SocialLinksGroup />
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.nameContainer}>
            <FormInput
              placeholder="Your name..."
              label="First Name"
              labelColor="semiDark"
              isRequired={true}
              value={firstName}
              onInputChange={setFirstName}
            />
            <FormInput
              placeholder="Your last name..."
              label="Last Name"
              labelColor="semiDark"
              isRequired={true}
              value={lastName}
              onInputChange={setLastName}
            />
          </div>
          <div className={styles.emailContainer}>
            <FormInput
              placeholder="Your email..."
              label="Email Address"
              labelColor="semiDark"
              isRequired={true}
              value={email}
              onInputChange={setEmail}
              type="email"
            />
          </div>
          <div className={styles.phoneContainer}>
            <FormInput
              placeholder="(111)-123-1234"
              label="Phone"
              labelColor="semiDark"
              value={phone}
              onInputChange={setPhone}
              type="tel"
            />
          </div>

          <h6>Select Subject?</h6>
          <div className={styles.radioContainer}>
            <label htmlFor="general-inquery">
              <input
                type="radio"
                id="general-inquery"
                value="general-inquery"
                onChange={() => setSelectedRadio('general-inquery')}
                checked={selectedRadio === 'general-inquery'}
              />
              General Inquery
            </label>
            <label htmlFor="events-support">
              <input
                type="radio"
                id="events-support"
                value="events-support"
                onChange={() => setSelectedRadio('events-support')}
                checked={selectedRadio === 'events-support'}
              />
              Your events support
            </label>
            <label htmlFor="customer-support">
              <input
                type="radio"
                id="customer-support"
                value="customer-support"
                onChange={() => setSelectedRadio('customer-support')}
                checked={selectedRadio === 'customer-support'}
              />
              Customer Support
            </label>
          </div>
          <div className={styles.messageContainer}>
            <label htmlFor="message" className={styles.messageLabel}>
              Message
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                placeholder="Write your message..."
              />
            </label>
          </div>
          <div className={styles.btnContainer}>
            <CustomButton
              type="submit"
              title="Send Message"
              colorScheme="black"
            />
          </div>
        </form>
      </div>
    </div>

    // <Container className="bg-white text-dark" fluid>
    //   <Row className="py-5">
    //     <Col xs="11" lg="8" className="mx-auto">
    //       <h2 className="fw-bold text-primary text-center">Contact Us</h2>
    //       <h5 className="text-center fw-bold">
    //         Any questions or remarks? Just write us a message!
    //       </h5>

    //       <Row className=" my-5 pt-4">
    //         <Col
    //           xs="12"
    //           lg="5"
    //           style={{
    //             backgroundImage: `url(/contact-form-background.png)`,
    //             backgroundSize: 'cover',
    //           }}
    //           className="text-white p-5 border-dark rounded-3"
    //         >
    //           <h3 className="fw-bold">Contact Information</h3>
    //           <h5 className="text-gray mt-3">
    //             Say something to start a live chat!
    //           </h5>

    //           <br />
    //           <br />
    //           <br />
    //           <br />
    //           <Row className="my-4">
    //             <Col xs="2">
    //               <FontAwesomeIcon
    //                 size="2x"
    //                 icon={faPhoneVolume}
    //                 className="ms-2 fs-sm-1"
    //               />
    //             </Col>
    //             <Col xs="10" className="h5 mb-0">
    //               +1 647 123 1234
    //             </Col>
    //           </Row>

    //           <Row className="my-4">
    //             <Col xs="2">
    //               <FontAwesomeIcon
    //                 size="2x"
    //                 icon={faEnvelope}
    //                 className="ms-2 fs-sm-1"
    //               />
    //             </Col>
    //             <Col xs="10" className="h5 mb-0">
    //               demo@gmail.com
    //             </Col>
    //           </Row>

    //           <Row className="my-4">
    //             <Col xs="2">
    //               <FontAwesomeIcon
    //                 size="2x"
    //                 title="My Orders"
    //                 icon={faLocationDot}
    //                 className="ms-2 fs-sm-1"
    //               />
    //             </Col>
    //             <Col xs="10" className="h5 mb-0">
    //               132 Queen Street, Toronto 02156 Canada
    //             </Col>
    //           </Row>

    //           <br />
    //           <br />
    //           <br />
    //           <br />
    //           <Nav className="flex-row my-5">
    //             <Nav.Link
    //               onClick={() =>
    //                 openSocialMediaInPopup(SOCIAL_MEDIA_URLS.TWITTER, 'twitter')
    //               }
    //               className="px-1"
    //             >
    //               <img
    //                 width={40}
    //                 height={40}
    //                 src="/twitter_icon.svg"
    //                 alt="twitter_icon logo"
    //               />
    //             </Nav.Link>
    //             <Nav.Link
    //               onClick={() =>
    //                 openSocialMediaInPopup(
    //                   SOCIAL_MEDIA_URLS.FACEBOOK,
    //                   'facebook'
    //                 )
    //               }
    //               className="px-1"
    //             >
    //               <img
    //                 width={40}
    //                 height={40}
    //                 src="/facebook.svg"
    //                 alt="facebook logo"
    //               />
    //             </Nav.Link>
    //             <Nav.Link
    //               onClick={() =>
    //                 openSocialMediaInPopup(SOCIAL_MEDIA_URLS.YOUTUBE, 'youtube')
    //               }
    //               className="px-1"
    //             >
    //               <img
    //                 width={40}
    //                 height={40}
    //                 src="/youtube.svg"
    //                 alt="youtube logo"
    //               />
    //             </Nav.Link>

    //             <Nav.Link
    //               onClick={() =>
    //                 openSocialMediaInPopup(
    //                   SOCIAL_MEDIA_URLS.FACEBOOK,
    //                   'facebook'
    //                 )
    //               }
    //               className="px-1"
    //             >
    //               <img
    //                 width={40}
    //                 height={40}
    //                 src="/instagram.svg"
    //                 alt="instagram logo"
    //               />
    //             </Nav.Link>
    //           </Nav>
    //         </Col>
    //         <Col xs="12" lg="7" className="p-5 shadow">
    //           <Form>
    //             <Form.Group className="my-4 d-flex">
    //               <div className="flex-grow-1 pe-2">
    //                 <Form.Label className="required">First Name</Form.Label>
    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Your name..."
    //                   className="border-dark"
    //                 />
    //               </div>
    //               <div className="flex-grow-1 ps-2">
    //                 <Form.Label className="required">Last Name</Form.Label>
    //                 <Form.Control
    //                   type="text"
    //                   placeholder="Your last name..."
    //                   className="border-dark"
    //                 />
    //               </div>
    //             </Form.Group>
    //             <Form.Group className="my-4">
    //               <Form.Label className="required">Email Address</Form.Label>
    //               <Form.Control
    //                 type="text"
    //                 placeholder="Your email..."
    //                 className="border-dark"
    //               />
    //             </Form.Group>
    //             <Form.Group className="my-4">
    //               <Form.Label>Phone</Form.Label>
    //               <Form.Control
    //                 type="text"
    //                 placeholder="(111)-123-1234"
    //                 className="border-dark"
    //               />
    //             </Form.Group>
    //             <br /> <br />
    //             <Form.Group className="my-4">
    //               <Form.Label as="h6" className="fw-bold my-3">
    //                 Select Subject?
    //               </Form.Label>
    //               <Form.Check
    //                 inline
    //                 label="General Inquiry"
    //                 name="group"
    //                 type={'radio'}
    //               />

    //               <Form.Check
    //                 inline
    //                 label="Your events support"
    //                 name="group"
    //                 type={'radio'}
    //               />

    //               <Form.Check
    //                 inline
    //                 label="Customer support"
    //                 name="group"
    //                 type={'radio'}
    //               />
    //             </Form.Group>
    //             <Form.Group className="my-4">
    //               <Form.Label className="small">Message</Form.Label>
    //               <Form.Control
    //                 type="text"
    //                 placeholder="Write your message"
    //                 className="border-0 border-bottom"
    //               />
    //             </Form.Group>
    //             <div className="d-flex mt-5">
    //               <Button
    //                 type="submit"
    //                 variant="dark"
    //                 className="border-dark rounded-2 py-3 px-5 ms-auto"
    //               >
    //                 Send Message
    //               </Button>
    //             </div>
    //           </Form>
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
    // </Container>
  );
}
