import React, { useState, useRef } from 'react';
// import Dropdown from 'react-bootstrap/Dropdown';
// import { Button } from 'react-bootstrap';
import styles from './LanguagePicker.module.scss';
import { useLanguage } from '../../context/useLanguage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import useOutsideClick from '../../utils/useOutsideClick';

function LanguagePicker() {
  const [showModal, setShowModal] = useState(false);
  const { language, changeLanguage, langFlags } = useLanguage();
  const dropdownRef = useRef();
  const toggleRef = useRef();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleLanguageChange = (lang) => {
    console.log('Language changed to:', lang);
    changeLanguage(lang);
    handleCloseModal();
  };

  // Use the custom hook to handle outside click of the dropdown
  useOutsideClick(dropdownRef, (event) => {
    if (
      (showModal,
      toggleRef.current && !toggleRef.current.contains(event.target))
    ) {
      setShowModal(false);
    }
  });

  return (
    <div className={styles.languagePicker}>
      <button
        className={styles.btn}
        onClick={() => {
          showModal ? handleCloseModal() : handleOpenModal();
        }}
        ref={toggleRef}
      >
        <span>
          <img src={langFlags[language].flag} alt={`${language} flag`} />
          {language}
          <>
            {showModal ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </>
        </span>
      </button>

      <div
        className={`${styles.modal} ${showModal ? styles.active : ''}`}
        ref={dropdownRef}
      >
        <div className={styles.btnsContainer}>
          {Object.keys(langFlags).map((lan, index) => {
            return (
              <button
                className={styles.changeBtn}
                onClick={() => handleLanguageChange(lan)}
                key={`language-${index}`}
              >
                <img
                  src={langFlags[lan].flag}
                  alt={`${langFlags[lan].lang} flag`}
                  className={styles.flagImg}
                />
                {lan}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LanguagePicker;
