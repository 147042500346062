import { triggerGoogleAuth } from '../../utils/APIUtils';
import styles from './AuthenticationFoot.module.scss';
import googleIcon from '../../assets/google-square.png';
import CustomButton from '../CustomButton/CustomButton';

export default function AuthenticationFoot() {
  return (
    <>
      <div className={styles.btnContainer}>
        <p className={styles.divider}>OR</p>
        <CustomButton
          title={
            <>
              <img src={googleIcon} alt="google logo" />
              <span>Sign-in with Google</span>
            </>
          }
          colorScheme="white"
          clickHandler={triggerGoogleAuth}
        />
      </div>
    </>
  );
}
