// Utility functions that are used to format data through out the code

/**
 * Capitalizes the first letter of every word in a string.
 * Assumption: input string only contains alphabets
 *
 * @param {string} str - The string to be capitalized.
 * @return {string} The string with the first letter of every word capitalized.
 */
const capitalizeStr = (str) => {
  str = str.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function (letter) {
    return letter.toUpperCase();
  });
  return str;
};

/**
 * Formats the given date into a string in the format 'YYYY-MM-DD'.
 *
 * @param {Date|string|number} date - The date to format. It can be a Date object, a string
 *                                   representation of the date, or a timestamp.
 * @return {string} The formatted date string.
 */
const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

// const getFormDataObj = (obj) => {
//     let formData = new FormData();
//     for ( let key in obj ) {
//         let kValue = obj[key];
//         if(kValue == undefined)
//             continue;
//         else if(Array.isArray(kValue)){
//             if(kValue.length){
//                 for(let i = 0; i < kValue.length; i++){
//                     if(kValue[i] instanceof File || typeof(kValue[i]) == "string"){
//                         formData.append(key, kValue[i]);
//                     }
//                     else {
//                         for(let k in kValue[i]){
//                             let fdKey = key + "[" + i + "]" + "." + k;
//                             if(kValue[i][k]){
//                                 formData.set(fdKey, kValue[i][k]);
//                             }

//                         }

//                     }
//                 }
//             }
//         }
//         else if(typeof(obj[key]) == "string"){
//             formData.append(key, obj[key]);
//         }
//         else{
//             formData.append(key, obj[key]);
//         }

//     }
//     return formData;
// }

/**
 * Converts form that includes fields such as images, etc. to a FormData
 * which is used to post request to server.
 *
 * @param {Object} obj - The form data object to be converted.
 * @param {FormData} [formData=null] - The FormData object to append the
 * converted form data to. If not provided, a new FormData object will be
 * created.
 * @param {string} [parentKey=''] - The parent key to be used when appending
 * the converted form data to the FormData object.
 * @return {FormData} The FormData object containing the converted form data.
 */
const getFormDataObj = (obj, formData = null, parentKey = '') => {
  if (!formData) {
    formData = new FormData();
  }

  console.log('inform data', obj);

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formKey = parentKey
        ? !isNaN(key)
          ? `${parentKey}[${key}]`
          : `${parentKey}.${key}`
        : key;

      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          if (typeof element === 'object') {
            Object.keys(element).forEach((nestedKey) => {
              const nestedValue = element[nestedKey];
              const nestedFormKey = `${formKey}[${index}].${nestedKey}`;
              if (
                nestedValue instanceof File ||
                typeof nestedValue === 'string'
              ) {
                formData.append(nestedFormKey, nestedValue);
              } else if (
                typeof nestedValue === 'boolean' ||
                typeof nestedValue === 'number'
              ) {
                formData.append(nestedFormKey, nestedValue.toString());
              } else if (
                typeof nestedValue === 'object' &&
                nestedValue !== null
              ) {
                getFormDataObj(nestedValue, formData, nestedFormKey);
              }
            });
          } else {
            formData.append(`${formKey}[[${index}]]`, element);
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        if (value instanceof File || typeof value === 'string') {
          formData.append(formKey, value);
        }
        getFormDataObj(value, formData, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }

  return formData;
};

/**
 * Opens an external link in a popup window.
 *
 * @param {string} url - The URL of the external link.
 * @param {string} name - The name of the popup window.
 * @param {string} [params] - The parameters for the popup window.
 * @return {Window} The opened window object.
 */
const openExternalLinkInPopupWindow = (url, name, params) => {
  let win = window.open(
    url,
    name,
    `${params ? params : 'width=600,height=600,top=85,left=500'}`
  );
  return win; // return window in case we need to play around with created window object
};

// Utility to deeply merge two objects
function mergeDeep(target, source) {
  if (typeof target == 'object' && typeof source == 'object') {
    for (const key in source) {
      if (typeof source[key] == 'object' && key in target) {
        mergeDeep(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
}

// const formData = new FormData();

//     const getFormDataObj = (obj, parentKey = '') => {
//       for (const key in obj) {
//         if (obj.hasOwnProperty(key)) {
//           const keyName = parentKey ? `${parentKey}.${key}` : key;
//           if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
//             getFormDataObj(obj[key], keyName);
//           } else if (Array.isArray(obj[key])) {
//             obj[key].forEach((item, index) => {
//               getFormDataObj(item, `${keyName}[${index}]`);
//             });
//           } else {
//             formData.append(keyName, obj[key]);
//           }
//         }
//       }
//       return formData
//     };

export {
  mergeDeep,
  capitalizeStr,
  formatDate,
  getFormDataObj,
  openExternalLinkInPopupWindow,
};
