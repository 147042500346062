import styles from './Accordion.module.scss';
import { useState } from 'react';
import plusIcon from '../../assets/plus.svg';
import minusIcon from '../../assets/minus.svg';

export default function Accordion({ number, title, content }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.accordion}>
      <div className={styles.accordionContainer}>
        <div className={styles.accordionTitleContainer}>
          <h4 className={styles.number}>{number}</h4>
          <h4 className={styles.title}>{title}</h4>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={isOpen ? styles.isOpen : ''}
          >
            <img src={isOpen ? minusIcon : plusIcon} alt="toggle icon" />
          </button>
        </div>
        <div
          className={
            isOpen
              ? `${styles.accordionContentContainer} ${styles.isOpen}`
              : styles.accordionContentContainer
          }
        >
          <p className={styles.content}>{content}</p>
        </div>
      </div>
    </div>
  );
}
