import React, { useRef } from 'react';
import styles from './Modal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '../../context/useModal';
import useOutsideClick from '../../utils/useOutsideClick';

export default function Modal() {
  const { modalContent, closeModal, showModal } = useModal();
  const modalRef = useRef(null);
  // Use the custom hook to handle outside click
  useOutsideClick(modalRef, () => {
    if (showModal) closeModal(); // Only set to false if it's currently true
  });

  return (
    <div
      className={`${styles.modalBackground} ${showModal ? '' : styles.hide}`}
    >
      <div className={styles.modal} ref={modalRef}>
        <button className={styles.closeBtn} onClick={closeModal}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
        <div className={styles.modalBody}>{modalContent}</div>
      </div>
    </div>
  );
}
