import { openExternalLinkInPopupWindow } from '../../utils/CommonUtils';
import styles from './SocialLink.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SocialLink({ link, icon, name }) {
  const openSocialMediaInPopup = (url, name) => {
    openExternalLinkInPopupWindow(url, name, null);
  };

  return (
    <button
      onClick={() => openSocialMediaInPopup(link, name)}
      className={styles.socialLink}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
}
