import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import { login2 } from '../../utils/APIUtils';
import FormInput from '../../components/FormInput/FormInput';
// import Error from '../shared/Error';
import useRecaptcha from '../../context/useRecaptcha';
import { useAuth } from '../../context/useAuth';
import ToastMessage from '../../shared/ToastMessage';
import AuthenticationHead from '../../components/AuthenticationHead/AuthenticationHead';
import AuthenticationFoot from '../../components/AuthenticationFoot/AuthenticationFoot';
import KeepMeSignAndForgot from '../../components/KeepMeSignAndForgot/KeepMeSignAndForgot';
import { verifyemail } from '../../utils/APIUtils';
import ConfirmVerificationCode from '../../components/ConfirmVerificationCode/ConfirmVerificationCode';
import styles from './Login.module.scss';
import { loginValidation } from '../../utils/authValidationUtils';
import CustomButton from '../../components/CustomButton/CustomButton';

const storageUtils = require('../../utils/LocalStorageUtils');

/**
 * Login function is a React functional component that exports a default function
 * named Login. This component is responsible for handling user login functionality.
 *
 * @return {JSX.Element} Returns a JSX element that renders the login form.
 */
export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  // States for controlled components
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberUser, setRememberUser] = useState(false);
  // Remaining states
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });
  const [pendingAuthToken, setPendingAuthToken] = useState('');
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [captchaResponse, setCaptchaResponsed] = useRecaptcha();

  /**
   * A useEffect hook that runs when the component mounts and when the user changes.
   * It checks if there is a logged user, if so, it navigates to the events page.
   *
   * @return {void}
   */
  useEffect(() => {
    if (auth.user) {
      navigate('/events');
    }
  }, [auth.user]);

  /**
   * A useEffect hook that runs when the component mounts.
   * It checks if there is a logged user, if so, it navigates to the events page.
   * It retrieves the email of a previously logged in user from local storage and sets the email state.
   *
   * @return {void}
   */
  useEffect(() => {
    const prevUser = storageUtils.getItem('email');
    if (prevUser) {
      setEmail(prevUser);
    }
  }, []);

  /**
   * Resets the form state.
   *
   * @return {void} This function does not return anything.
   */
  const resetFormState = () => {
    setErrors({});
    setEmail('');
    setPassword('');
    setPendingAuthToken('');
    setEmailVerificationCode('');
    if (process.env.NODE_ENV !== 'development') {
      window.grecaptcha.reset();
    }
  };

  /**
   * A function that handles the successful login response.
   *
   * @param {Object} loginData - The login data object.
   * @param {Object} response - The response object.
   * @return {void}
   */
  const handleLoginSuccess = (email, response) => {
    console.log('handleLoginSuccess');
    let loggedInUser = response.data;
    console.log(
      'loggedInUser in handleLoginSuccess (response.data):',
      loggedInUser
    );

    console.log(loggedInUser);
    // store the user email in localStorage if the user wants to be remembered
    if (rememberUser) {
      storageUtils.setItem('email', email);
    }
    auth.setUser(loggedInUser);
    resetFormState();

    setTimeout(() => {
      navigate('/events');
    }, 100);
  };

  /**
   * A function that handles login errors.
   *
   * @param {Error} err - The error object.
   * @return {void}
   */
  const handleLoginError = (err) => {
    if (!err.error) {
      err.error = 'Something went wrong please try again later';
    }
    setErrors({ auth: err.response.data.message });
    setToast({
      show: true,
      success: false,
      msg: err.response.data.message,
    });

    if (process.env.NODE_ENV !== 'development') {
      window.grecaptcha.reset();
    }
  };

  /**
   * A function that submits the form.
   *
   * @return {void} This function does not return anything.
   */
  const submitForm = (e) => {
    e.preventDefault();
    const errs = loginValidation(email, password);
    if (Object.keys(errs).length === 0) {
      const loginData = {
        email: email,
        password: password,
        rememberUser: rememberUser,
        // captchaResponse: captchaResponse
      };
      login2(loginData, captchaResponse)
        .then((res) => {
          console.log('login2 res: ', res);
          if (res.data.pending_authentication_token) {
            setPendingAuthToken(res.data.pending_authentication_token);
          } else {
            // TODO: Test to check if the response contains the user data, if not, inform user of failure
            console.log('response from login2 is: ', res.data);
            handleLoginSuccess(email, res);
          }
        })
        .catch((err) => {
          handleLoginError(err);
        });
    } else {
      setErrors(errs);
    }
  };

  /**
   * Handles the successful authentication response.
   *
   * @param {Object} registrationData - The registration data object.
   * @param {Object} response - The response object containing user data.
   * @return {void} This function does not return anything.
   */
  const handleAuthenticationSuccess = (registrationData, response) => {
    console.log('response in handleAuthenticationSuccess', response);

    let loggedInUser = response.data.user;
    console.log('loggedInUser', loggedInUser);

    // store the user's email in localStorage if remember me is checked
    if (rememberUser) {
      storageUtils.setItem('email', registrationData.email);
    }

    // Set user on the auth context
    auth.setUser(loggedInUser);

    // Navigate to events
    setTimeout(() => {
      navigate('/events');
    }, 100);
  };

  /**
   * Handles the form submission of verification code.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmitVerificationCode = (e) => {
    e.preventDefault();
    verifyemail(emailVerificationCode, pendingAuthToken)
      .then((res) => {
        const userData = {
          email: email,
          password: password,
          // captchaResponse: captchaResponse
        };
        resetFormState();
        handleAuthenticationSuccess(userData, res);
      })
      .catch((e) => {
        setEmailVerificationCode('');
      });
  };

  const clearErrorWhenTyping = () => {
    setErrors({});
  };

  return (
    <div className={styles.login}>
      {!pendingAuthToken ? (
        <div className={styles.loginCard}>
          <AuthenticationHead isLogin={true} />
          <form onSubmit={submitForm}>
            <FormInput
              ariaLabel={'username'}
              controlId={'loginEmail'}
              label="Username"
              type="email"
              placeholder="Enter your email address"
              value={email}
              onInputChange={setEmail}
              error={errors.email}
              onClearError={clearErrorWhenTyping}
            />
            <FormInput
              ariaLabel={'password'}
              controlId={'loginPassword'}
              label={'Password'}
              type={'password'}
              placeholder={'Password'}
              value={password}
              onInputChange={setPassword}
              minLength={8}
              error={errors.password}
              onClearError={clearErrorWhenTyping}
            />
            <KeepMeSignAndForgot
              onChangeChecked={setRememberUser}
              rememberUser={rememberUser}
            />
            <p className={styles.legal}>
              {' '}
              By continuing past this page, you agree to the Terms of Use and
              understand that information will be used as described in our
              Privacy Policy.
            </p>
            <CustomButton type={'submit'} title={'Log In'} />
          </form>
          <AuthenticationFoot />
        </div>
      ) : (
        <ConfirmVerificationCode
          handleSubmitVerificationCode={handleSubmitVerificationCode}
          emailVerificationCode={emailVerificationCode}
          setEmailVerificationCode={setEmailVerificationCode}
          clearErrorWhenTyping={clearErrorWhenTyping}
          errors={errors}
        />
      )}

      {/* <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      /> */}
    </div>
  );
}
