import styles from './FormInput.module.scss';
import errorImg from '../../assets/error.svg';
export default function FormInput({
  ariaLabel,
  label,
  type,
  placeholder,
  maxLength,
  value,
  onInputChange,
  minLength,
  error,
  onClearError,
  labelColor,
  isRequired,
}) {
  const handleChange = (e) => {
    if (onClearError && error) onClearError();
    onInputChange(e.target.value);
  };

  const getLabelText = () => {
    if (isRequired) {
      return (
        <p className={styles.label}>
          {label} <span>*</span>
        </p>
      );
    } else {
      return <p className={styles.label}>{label}</p>;
    }
  };

  // Label color implemented is semiDark. By default it is white.
  return (
    <label
      aria-label={ariaLabel}
      className={labelColor ? styles[labelColor] : ''}
    >
      {getLabelText()}
      <input
        type={type || 'text'}
        placeholder={placeholder}
        maxLength={maxLength ? maxLength : '150'}
        value={value}
        onChange={handleChange}
        minLength={minLength}
        className={error ? styles.error : ''}
      />
      {error && (
        <p className={styles.errorMsg}>
          <img src={errorImg} alt="error" />
          {error}
        </p>
      )}
    </label>
  );
}
