import React from 'react';
import { navigateToPromoter } from '../../utils/NavigationUtils';
import { useTranslation } from 'react-i18next';
import styles from './About.module.scss';
import testemonialImg from '../../assets/testimonial.jpeg';
import CustomButton from '../../components/CustomButton/CustomButton';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

/**
 * Renders a section about the company Ticket Window Inc.
 *
 * @return {JSX.Element} The JSX element representing the About section.
 */

const About = () => {
  const { t } = useTranslation();

  const decodeContent = (key) => {
    try {
      const raw = t(key); // Fetch the translation string using the key
      console.log(raw);
      const mod = raw
        .replace(/“/g, '"')
        .replace(/”/g, '"')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r');
      console.log(mod);

      const content = JSON.parse(mod); // Parse the translation as JSON
      console.log(content);

      const converter = new QuillDeltaToHtmlConverter(content.ops, {}); // Convert Quill Delta to HTML
      const tag = converter.convert();
      return tag; // Return the HTML content
    } catch (error) {
      console.error('Error decoding content:', error);
      return ''; // Return an empty string or fallback content in case of an error
    }
  };

  return (
    <div className={styles.about}>
      <div className={styles.aboutContainer}>
        {/* <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: decodeContent('about.pageTitle') }}
        ></div> */}
        <h3 className={styles.aboutTitle}>{t('about.pageTitle')}</h3>
        <h5 className={styles.subHeading}>{t('about.firstSectionTitle')}</h5>
        <p className={styles.text}>{t('about.firstSectionText')}</p>
        <h5 className={styles.subHeading}>{t('about.secondSectionTitle')}</h5>
        <p className={styles.text}>{t('about.secondSectionTextP1')}</p>
        <p className={styles.text}>{t('about.secondSectionTextP2')}</p>

        <div className={styles.cta}>
          <div className={styles.imgContainer}>
            <img src={testemonialImg} alt="A person with positive feedback" />
          </div>
          <div className={styles.actionContainer}>
            <h3>{t('about.ctaText')}</h3>
            <div className={styles.btnContainer}>
              <CustomButton
                clickHandler={navigateToPromoter}
                title={t('about.ctaBtnLabel')}
              ></CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
