import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import Error from '../../shared/Error';
import { useAuth } from '../../context/useAuth';

/**
 * ErrorHandler is a React functional component that handles errors.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} - The rendered component.
 */
const ErrorHandler = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  switch (get(location.state, 'errorStatusCode')) {
    case 401:
      auth.logout();
      navigate('/login');
      return children;

    case 404:
      return <Error />;

    case 403:
      return <Error code={403} message="Unauthorized Access" />;

    case 500:
      return <Error code={500} message="Internal Server Error" />;

    default:
      return children;
  }
};

export default ErrorHandler;
