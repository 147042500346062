import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
// import Error from '../shared/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../utils/authValidationUtils';
// import ReCAPTCHA from 'react-google-recaptcha';
import { sendResetPasswordEmail } from '../../utils/APIUtils';
// import useRecaptcha from "../hooks/useRecaptcha";

/**
 * React function component that exports and renders HTML elements for the forgot password modal.
 *
 * @return {JSX.Element} The JSX element that renders the forgot password modal.
 */
export default function ForgotPassword() {
  // State hook to set the captcha response.
  // const [captchaResponse, setCaptchaResponsed] = useRecaptcha();

  // State hook to set an email.
  const [email, setEmail] = useState('');

  // State hook to manage errors.
  const [errors, setErrors] = useState({});
  const [resetLinkSent, setResetLinkSent] = useState(false);

  /**
   * Handles the form submission event.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    const emailError = validateEmail(email);

    if (emailError) {
      errors.email = emailError;
    }

    // if(!captchaResponse.trim()) {
    //     errors.captcha = "Captcha is required";
    // }

    if (Object.keys(errors).length === 0) {
      submitForm();
    }

    setErrors(errors);
  };

  /**
   * Submits the form data.
   *
   * @return {void} This function does not return anything.
   */
  const submitForm = () => {
    console.log('send email to: ' + email);
    const resetData = {
      email: email,
      // captchaResponse: captchaResponse
    };

    sendResetPasswordEmail(resetData)
      .then((res) => {
        // window.grecaptcha.reset();
        setResetLinkSent(true);
        // setCaptchaResponsed("");
      })
      .catch((err) => {
        console.log(err.response);
        // window.grecaptcha.reset();
        setResetLinkSent(false);
        // setCaptchaResponsed("");
        setErrors({ api: err.response.data.error.message });
      });
  };

  // const onCaptchaChange = (value) => {
  //     const token = captchaRef.current.getValue();
  //     setCaptchaResponsed(token);
  //     console.log(token)
  // }

  return (
    <div
      id="banner"
      className="h-100 d-flex align-items-center justify-content-center bg-header"
    >
      <Card className="login-box">
        <Card.Body className="p-4">
          <Card.Title className="text-primary text-uppercase mb-0">
            {resetLinkSent ? 'Check your email' : 'Forgot Password'}
          </Card.Title>
          <Card.Text className="fs-12">
            {resetLinkSent
              ? 'We sent you a password reset link'
              : "No worries, we'll send you reset instructions"}
          </Card.Text>

          <Form onSubmit={handleSubmit}>
            {!resetLinkSent && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold mb-1" aria-label="username">
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <Form.Text className="text-danger">{errors.email}</Form.Text>
                )}
              </Form.Group>
            )}

            {/* use css to show captcha conditionally */}
            <div className={`mb-3 ${resetLinkSent ? 'd-none' : 'd-block'}`}>
              {/* <div id="captchaDiv"></div> */}
              {/* <ReCAPTCHA 
                            sitekey={SITE_KEY} // demo site key added for testing => 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                            onChange={onCaptchaChange}
                            ref={captchaRef}   
                        />  */}
              {/* {errors.captcha && <Form.Text className="text-danger">{errors.captcha}</Form.Text>}
                        {errors.api && <Form.Text className="text-danger">{errors.api}</Form.Text> } */}
            </div>

            {resetLinkSent && (
              <Card.Text className="fs-12 mb-2">
                {' '}
                Didn't recieve the email?{' '}
              </Card.Text>
            )}

            {resetLinkSent && (
              <Button
                onClick={() => setResetLinkSent(false)}
                variant="primary"
                type="button"
                className="w-100 text-white fw-bold"
              >
                Click to resend
              </Button>
            )}

            {!resetLinkSent && (
              <Button
                variant="primary"
                type="submit"
                className="w-100 text-white fw-bold"
              >
                Reset your password
              </Button>
            )}
          </Form>

          <div className="mt-2">
            <Link
              to="/login"
              className="p-0 text-decoration-none mt-2 fs-12 fw-bold"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              Back to Login
            </Link>
          </div>
        </Card.Body>
      </Card>
      {/* <Error /> */}
    </div>
  );
}
