import styles from './BrandLogo.module.scss';
import darkLogo from '../../assets/logo-dark.svg';
import smallLogo from '../../assets/small-logo.svg';
// import lightLogo from '../../assets/logo-light.svg';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

export default function BrandLogo() {
  return (
    <Link to={'/'} className={styles.logoContainer}>
      <img
        // src={islightTheme ? lightLogo : darkLogo}
        src={darkLogo}
        alt="Eventlinx Logo"
        className={styles.logo}
      />
      <img
        // src={islightTheme ? lightLogo : darkLogo}
        src={smallLogo}
        alt="Eventlinx Logo"
        className={styles.smallLogo}
      />
    </Link>
  );
}
