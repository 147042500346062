export default function PromoterLandingPage({ subdomain }) {
  console.log('subdomain in PromoterLandingPage: ', subdomain);

  return (
    <div>
      <h1 style={{ color: 'red', fontSize: '50px' }}>
        Welcome to {subdomain}'s Landing Page
      </h1>
    </div>
  );
}
