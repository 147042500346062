import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { ProvideModal } from '../context/useModal';
import Modal from './Modal/Modal';

/**
 * The main layout component for the application.
 *
 * @returns {JSX.Element} The rendered layout component.
 */
export default function Layout() {
  return (
    <ProvideModal>
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <Header />
        <main
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Outlet />
        </main>
        <Modal />
        <Footer />
      </div>
    </ProvideModal>
  );
}
