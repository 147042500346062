import FormInput from '../FormInput/FormInput';
import './ConfirmVerificationCode.module.scss';
import styles from './ConfirmVerificationCode.module.scss';

export default function ConfirmVerificationCode({
  handleSubmitVerificationCode,
  emailVerificationCode,
  setEmailVerificationCode,
  clearErrorWhenTyping,
  errors,
}) {
  return (
    <div className={styles.verificationCard}>
      <h6>We have sent a verification code on your email.</h6>
      <h6>Please enter the 6 digit code to login.</h6>
      <form onSubmit={handleSubmitVerificationCode}>
        <FormInput
          ariaLabel={'verificationCode'}
          controlId={'verificationCode'}
          label="Enter Code"
          type="text"
          placeholder="Enter code here"
          value={emailVerificationCode}
          onInputChange={setEmailVerificationCode}
          error={errors.email}
          onClearError={clearErrorWhenTyping}
        />
        <button className={styles.verifyBtn} type="submit">
          Submit Verification Code
        </button>
      </form>
    </div>
  );
}
