import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

/**
 * ScheduleDemo component.
 *
 * @return {React.Element} The rendered ScheduleDemo component.
 */
const ScheduleDemo = () => {
  return (
    <Container className="bg-white text-dark" fluid>
      <Row className="py-5">
        <Col xs="11" lg="8" className="mx-auto">
          <Row className=" my-5 pt-4">
            <Col xs="12" lg="6">
              <h1 className="fw-bold my-5 pb-3">START NOW FOR FREE</h1>

              <div className="d-flex mb-5 pb-3">
                <div className="p-3 bg-light border-light rounded-circle d-flex align-items-center justify-content-center">
                  <img
                    src="/user-check-icon.svg"
                    alt="user icon"
                    height="30"
                    width="30"
                  />
                </div>
                <div className="fw-bold ps-3 w-50">
                  Intuitive event creation and management tools
                </div>
              </div>

              <div className="d-flex mb-5 pb-3">
                <div className="p-3 bg-light border-light rounded-circle d-flex align-items-center justify-content-center">
                  <img
                    src="/ticket-icon.svg"
                    alt="ticket icon"
                    height="nce30"
                    width="30"
                  />
                </div>
                <div className="fw-bold ps-3 w-50">
                  Customizing ticket types, pricing, and availability.
                </div>
              </div>

              <div className="d-flex mb-5 pb-3">
                <div className="p-3 bg-light border-light rounded-circle d-flex align-items-center justify-content-center">
                  <img
                    src="/dollor-icon.svg"
                    alt="dollor icon"
                    height="30"
                    width="30"
                  />
                </div>
                <div className="fw-bold ps-3 w-50">
                  Connect to Stripe get paid sooner
                </div>
              </div>
            </Col>
            <Col
              xs="12"
              lg="6"
              className="p-5 shadow bg-header border-dark text-white radius-20"
            >
              <h3 className="fw-bold">Book a free demo</h3>
              <Form>
                <Form.Group className="my-4 d-flex">
                  <div className="flex-grow-1 pe-2">
                    <Form.Label className="required">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your name..."
                      className="border-dark"
                    />
                  </div>
                  <div className="flex-grow-1 ps-2">
                    <Form.Label className="required">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your last name..."
                      className="border-dark"
                    />
                  </div>
                </Form.Group>

                <Form.Group className="my-4">
                  <Form.Label className="required">Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your email..."
                    className="border-dark"
                  />
                </Form.Group>

                <Form.Group className="my-4">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="(111)-123-1234"
                    className="border-dark"
                  />
                </Form.Group>

                <Form.Group className="my-4">
                  <Form.Label className="small">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="text"
                    placeholder="Write your message"
                    className="border-0 border-bottom"
                  />
                </Form.Group>

                <div className="d-flex mt-5">
                  <Button
                    type="submit"
                    variant="primary"
                    className="border-primary rounded-2 text-white py-3 px-5 w-100"
                  >
                    BOOK MY DEMO
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>

        <Col xs="12" className="demo-page-section2 mt-5 pt-5">
          <Row>
            <Col xs="11" lg="8" className="mx-auto">
              <h1 className="fw-bold my-4 w-50">
                Innovative way to sell advance tickets
              </h1>
              <h2>Branded to you</h2>
            </Col>
          </Row>
        </Col>

        <Col xs="12" className="mt-5 pt-5">
          <Row>
            <Col xs="11" lg="8" className="mx-auto">
              <div className="bg-header text-white py-5">
                <h1 className="fw-bold mb-0 text-center">
                  SCAN TICKETS WITH ADDED SECURITY
                </h1>
              </div>

              <div
                className="py-4"
                style={{
                  background: 'rgba(121, 168, 59, 0.35)',
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    position: 'absolute',
                    height: '100%',
                    right: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                  src="/woman-scanning.png"
                  alt="woman scanning"
                />
                <div className="d-flex p-5">
                  <div className="p-4 bg-white border-white rounded-circle">
                    <img src="/qrcode-icon.svg" alt="qecode  icon" />
                  </div>
                  <div className="ps-3 w-50">
                    <h6 className="fw-bold">Barcode scanning</h6>
                    Scanning the smartphone screen or printed confirmation of
                    each attendee to quickly flash their record.
                  </div>
                </div>
              </div>

              <div
                className="py-4"
                style={{
                  background: 'rgba(121, 168, 59, 0.13)',
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    position: 'absolute',
                    height: '100%',
                    right: '200px',
                    top: '-45%',
                    zIndex: 3,
                  }}
                  src="/mobile-with-logo.png"
                  alt="woman scanning"
                />
                <img
                  style={{
                    position: 'absolute',
                    height: '100%',
                    right: '125px',
                    top: '-25%',
                    zIndex: 2,
                  }}
                  src="/mobile-with-qrcode.png"
                  alt="woman scanning"
                />
                <div className="d-flex p-5">
                  <div className="p-4 bg-white border-white rounded-circle">
                    <img src="/fraud-detect-icon.svg" alt="fraud detect icon" />
                  </div>
                  <div className="ps-3 w-50">
                    <h6 className="fw-bold">Fraud detection</h6>
                    Being sure that attendees cannot use the same ticket to
                    enter the event.
                  </div>
                </div>
              </div>

              <div
                className="py-4"
                style={{ background: 'rgba(121, 168, 59, 0.04)' }}
              >
                <div className="d-flex p-5">
                  <div className="p-4 bg-white border-white rounded-circle">
                    <img src="/statistics-icon.svg" alt="statistics" />
                  </div>
                  <div className="ps-3 w-50">
                    <h6 className="fw-bold">Real-time statics</h6>A real-time
                    overview of how many tickets have been scanned to stay on
                    top of how many guests have arrived and how many can still
                    be expected.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs="12" className="my-5 py-5">
          <Row className="mb-5 pb-5">
            <Col xs="11" lg="8" className="mx-auto">
              <h1 className="fw-bold my-5 text-center">
                A new innovation and experience
              </h1>

              <Row className="pt-5">
                <Col xs="12" lg="6" className="px-4">
                  <div className="laptop-background">
                    <img
                      src="/streaming-demo.gif"
                      alt="streaming demo"
                      width="100%"
                    />
                  </div>
                </Col>
                <Col xs="12" lg="6" className="px-4 d-flex align-items-center">
                  <div>
                    <h2 className="fw-bold mb-3">Streaming(Coming soon)</h2>
                    <h3>Get Ready for a new experience in fall 2024</h3>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col
          xs="12"
          className="mt-5 pt-5 bg-header text-white position-relative"
          style={{ height: '500px' }}
        >
          <Row className="mb-5 pb-5">
            <Col xs="11" lg="8" className="mx-auto">
              <h1 className="fw-bold my-4 text-center">
                INFLUENCERS SELL MORE TICKETS
              </h1>
              <h2 className="text-center">
                Maximize Your Earnings with Our Affiliate Marketing Program!
              </h2>

              <Row className="mt-5 pt-5">
                <Col xs="12" className="d-flex pe-5">
                  <Button className="rounded-pill ms-auto bg-white border-white text-dark me-4 px-5 py-3">
                    <h5 className="mb-0">Book a Demo</h5>
                  </Button>
                  <Button className="rounded-pill bg-primary border-primary text-white px-5 py-3 me-5">
                    <h5 className="mb-0">Create Event</h5>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <img
            src="/influencer.png"
            alt="streaming demo"
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '250px',
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" className="my-5 py-5">
          <Row className="mb-5 pb-5">
            <Col
              xs="11"
              lg="8"
              className="mx-auto d-flex justify-content-center"
            >
              <div className="text-center w-50 my-5">
                <h3 className="fw-bold my-4 text-center">
                  Stay up to date with EventLinx{' '}
                </h3>
                <Form.Control
                  type="text"
                  placeholder="Enter your email address"
                />
                <Button
                  variant="primary"
                  className="text-white mt-4 rounded-pill py-2 px-4"
                >
                  {' '}
                  Subscribe
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ScheduleDemo;
