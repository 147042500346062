import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Nav,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import {
  faArrowRotateRight,
  faCamera,
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTriangleExclamation,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getUserDetails,
  resetStripeLink,
  updateContactInfo,
} from '../../../utils/APIUtils';
import { useAsync } from '../../../context/useAsync';
import validateContactInfo from './contactInfoValidator';
import ToastMessage from '../../../shared/ToastMessage';
import { USER_ROLES } from '../../../constants';

/**
 * An enumeration representing the type of business entity.
 * @enum {string}
 * @readonly
 */
const BUSS_TYPES_ENUM = Object.freeze({
  CORPORATION: 'CORPORATION',
  NOT_FOR_PROFIT: 'NOT_FOR_PROFIT',
  CHARITY: 'CHARITY',
  INDEPENDENT: 'INDEPENDENT',
});

/**
 * An enumeration representing the province entity.
 * @enum {string}
 * @readonly
 */
const PROVINCE_ENUM = Object.freeze({
  NL: 'Newfoundland and Labrador (NL)',
  PE: 'Prince Edward Island (PE)',
  ON: 'Ontario (ON)',
  MB: 'Manitoba (MB)',
  SK: 'Saskatchewan (SK)',
  AB: 'Alberta (AB)',
  BC: 'British Columbia (BC)',
  YK: 'Yukon (YK)',
  NT: 'Northwest Territories (NT)',
  NU: 'Nunavut (NU)',
});

/**
 * An enumeration representing the type of business entity.
 * @enum {string}
 * @readonly
 */
const BUSINESS_TYPE = Object.freeze({
  /** Corporation */
  CORPORATION: 'Corporation',
  /** Not for Profit */
  NOT_FOR_PROFIT: 'Not for Profit',
  /** Charity */
  CHARITY: 'Charity',
  /** Independent */
  INDEPENDENT: 'Independent',
});

/**
 * An enumeration representing the types of addresses.
 * @enum {string}
 * @readonly
 */
const ADDRESS_TYPES = Object.freeze({
  /** Principal address */
  PRINCIPAL: 'PRINCIPAL',
  /** Billing address */
  BILLING: 'BILLING',
});

/**
 * The ContactInfo function is the main component for handling the contact
 * information of the user.
 *
 * @return {JSX.Element} The JSX element representing the ContactInfo component.
 */
const ContactInfo = () => {
  // refs
  const inputRef = React.useRef(null);
  const profileImageRefDiv = React.useRef(null);

  const { data, loading, error, run } = useAsync(null);

  // State hooks
  const [userDetails, setUserDetails] = useState(null);
  const [errors, setErrors] = useState(null);

  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });
  // State hook to check data entered by user is saved.
  const [isSaving, setIsSaving] = useState(false);
  //State hook  to set the URL for the stripe account.
  const [stripeAccountUrl, setStripeAccountUrl] = useState('');

  /**
   * This useEffect hook is used to fetch user details and set the state on component mount.
   * It also handles the case where the addressDetailsList is not present or has less than 2 elements.
   *
   * @return {void}
   */
  useEffect(() => {
    run(() => getUserDetails()).then((res) => {
      console.log(res.data);
      if (!res.data.addressDetailsList) {
        /**
         * If addressDetailsList is not present, create two elements with addressType as PRINCIPAL and BILLING.
         */
        res.data.addressDetailsList = [
          getAddressDTO(ADDRESS_TYPES.PRINCIPAL),
          getAddressDTO(ADDRESS_TYPES.BILLING),
        ];
      } else if (res.data.addressDetailsList.length < 2) {
        /**
         * If addressDetailsList has less than 2 elements, add another element with the opposite addressType.
         */
        let addressType =
          res.data.addressDetailsList[0].addressType == ADDRESS_TYPES.BILLING
            ? ADDRESS_TYPES.PRINCIPAL
            : ADDRESS_TYPES.BILLING;
        res.data.addressDetailsList.push(getAddressDTO(addressType));
      }

      /**
       * Maintain the sequence of addressDetailsList based on addressType.
       */
      res.data.addressDetailsList.sort((a, b) =>
        a.addressType < b.addressType
          ? 1
          : b.addressType < a.addressType
          ? -1
          : 0
      );

      if (res.data.profileImageUrl) {
        setTimeout(() => {
          /**
           * Preview the uploaded image.
           */
          previewUploadedImage(res.data.profileImageUrl);
        }, 0);
      }
      /**
       * Set the userDetails state with the response data.
       */
      setUserDetails(res.data);
      /**
       * Set the stripeAccountUrl state with the response data.
       */
      setStripeAccountUrl(res.data.stripeAccountUrl);
    });
  }, []);

  /**
   * Returns an address DTO object with the provided parameters.
   *
   * @param {string} addressType - The type of address.
   * @param {string} addressLine1 - The first line of the address.
   * @param {string} addressLine2 - The second line of the address.
   * @param {string} city - The city of the address.
   * @param {string} province - The province of the address.
   * @param {string} postalCode - The postal code of the address.
   * @param {string} country - The country of the address.
   * @param {boolean} profileImageUpdated - Indicates if the profile image has been updated.
   * @return {Object} An address DTO object with the provided parameters.
   */
  const getAddressDTO = (
    addressType,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    country,
    profileImageUpdated
  ) => {
    return {
      addressType: addressType,
      addressLine1,
      addressLine2,
      city,
      province,
      postalCode,
      country,
      profileImageUpdated,
    };
  };

  /**
   * Triggers the input when the button is clicked.
   *
   * @return {void}
   */
  const onButtonClick = () => {
    inputRef.current.click();
  };

  /**
   * Validates the contact information.
   *
   * @return {boolean} Returns true if the contact information is valid, false otherwise.
   */
  const validate = () => {
    let error = validateContactInfo(userDetails);
    if (Object.entries(error).length > 0) {
      setErrors(error);
      return false;
    }
    return true;
  };

  /**
   * Handles the form submission event.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userDetails);
    let isValid = validate();

    if (isValid) {
      setIsSaving(true);
      updateContactInfo(userDetails)
        .then((res) => {
          console.log(res);
          setToast({ show: true, msg: 'Updated Successfully', success: true });
          setIsSaving(false);
        })
        .catch((err) => {
          console.log(err);
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
          setIsSaving(false);
        });
    }
  };

  /**
   * Handles the change event of the file input element.
   *
   * @param {Event} e - The change event object.
   * @return {void} This function does not return anything.
   */
  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
      console.log(e.target.files[0]);
      previewUploadedImage(e.target.files[0]);
      setUserDetails({ ...userDetails, profileImageUpdated: true });
    }
  };

  /**
   * This function shows the preview of the uploaded image.
   *
   * @param {File} file - The file object representing the uploaded image.
   * @return {void} This function does not return anything.
   */

  const previewUploadedImage = (file) => {
    // setIsUploaded(true);
    if (typeof file.name == 'string') {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const uploaded_image = reader.result;
        profileImageRefDiv.current.style.backgroundImage = `url(${uploaded_image})`;
        setUserDetails({ ...userDetails, profileImage: file });
      });
      reader.readAsDataURL(file);
    } else {
      profileImageRefDiv.current.style.backgroundImage = `url(${file})`;
    }
  };

  /**
   * This function removes the profile image.
   *
   * @return {void} This function does not return anything.
   */
  const removeProfileImage = () => {
    setUserDetails({
      ...userDetails,
      profileImage: null,
      profileImageUpdated: true,
    });
    profileImageRefDiv.current.style.backgroundImage = `url(/user-icon.png)`;
  };

  /**
   * This function handles the changes in address field.
   *
   * @param {Event} e - The event object representing the change event.
   * @param {number} i - The index of the address in the addressDetailsList array.
   * @return {void} This function does not return anything.
   */
  const handleAddressChange = (e, i) => {
    let state = { ...userDetails };
    const { name, type, id, value, checked } = e.target;

    state.addressDetailsList[i][id] = value;
    setUserDetails(state);
  };

  /**
   * This function handles the changes in the form fields.
   *
   * @param {Event} e - The event object representing the change event.
   * @return {void} This function does not return anything.
   */
  const handleChange = (e) => {
    let state = { ...userDetails };
    const { name, type, id, value, checked } = e.target;

    if (type == 'checkbox') {
      state[name] = checked;
    } else {
      state[id] = value;
    }

    setUserDetails(state);
  };

  /**
   * This function is used to handle the changes for "same as billing address" checkbox.
   *
   * @param {Event} e - The event object representing the change event.
   * @return {void} This function does not return anything.
   */
  const handleAddressSameCheckboxChange = (e) => {
    // copy billing address
    let billingAddress = userDetails.addressDetailsList.filter(
      (dto) => dto.addressType == ADDRESS_TYPES.PRINCIPAL
    )[0];

    setUserDetails({
      ...userDetails,
      addressDetailsList: [
        { ...billingAddress },
        {
          ...billingAddress,
          addressType: ADDRESS_TYPES.BILLING,
        },
      ],
      billingAddressSame: e.target.checked,
    });

    // console.log({
    //     ...userDetails,
    //     addressDetailsList:[
    //         {...billingAddress},
    //         {
    //             ...billingAddress,
    //             addressType : ADDRESS_TYPES.BILLING,
    //         }
    //     ],
    //     billingAddressSame: e.target.checked
    // })
  };

  /**
   * This function is used to refresh the stripe link.
   *
   * @return {void} This function does not return anything.
   */
  const refreshStripeLink = () => {
    resetStripeLink()
      .then((res) => {
        setStripeAccountUrl(res.data);
        setToast({
          show: true,
          msg: 'Link Refreshed Successfully',
          success: true,
        });
      })
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
  };

  /**
   * Renders loading spinner if loading is true, otherwise renders the contact information form.
   *
   * @return {boolean} True if loading is true, false otherwise.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <>
      <h2 className="fw-bold mt-3 mb-0">Contact Information</h2>
      <span>Update your account details anytime.</span>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs="10">
            <Row>
              <Col xs="6">
                <h5 className="mt-4 fw-bold mb-3">Basic Information</h5>
                <Form.Group controlId="firstName" className="mb-3">
                  <Form.Label className="required">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name..."
                    value={userDetails.firstName}
                    onChange={handleChange}
                  />
                  {errors && errors.name && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.name}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="lastName" className="mb-3">
                  <Form.Label className="required">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name..."
                    value={userDetails.lastName}
                    onChange={handleChange}
                  />
                  {errors && errors.name && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.name}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="email" className="mb-3">
                  <Form.Label className="required">Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="email..."
                    value={userDetails.email}
                    onChange={handleChange}
                  />
                  {errors && errors.email && (
                    <Form.Text className="text-danger">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                      {errors.email}
                    </Form.Text>
                  )}
                </Form.Group>

                <div className="d-flex align-items-center mb-3">
                  <Form.Group className="mb-3 me-4 flex-grow-1">
                    <Form.Label className="required">Phone</Form.Label>
                    <div className="d-flex align-items-start">
                      <div>
                        <Form.Control
                          id="phoneNumber"
                          type="text"
                          placeholder="xxxxxxxxxx"
                          value={userDetails.phoneNumber}
                          onChange={handleChange}
                        />

                        {errors && errors.phoneNumber && (
                          <Form.Text className="text-danger">
                            <FontAwesomeIcon
                              icon={faTriangleExclamation}
                              className="me-2"
                            />
                            {errors.phoneNumber}
                          </Form.Text>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="website" className="mb-3 flex-grow-1">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="website..."
                      value={userDetails.website}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>

                <Form.Group controlId="businessName" className="mb-3">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="business name..."
                    value={userDetails.businessName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="businessType" className="me-3 w-50">
                  <Form.Label>Business Type</Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    value={userDetails.businessType}
                  >
                    <option value="">Select</option>
                    {Object.keys(BUSS_TYPES_ENUM).map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {BUSINESS_TYPE[item]}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col
                xs="6"
                className="d-flex align-items-center justify-content-center"
              >
                <div className="text-center mb-5">
                  <p>Profile Image</p>
                  <div
                    ref={profileImageRefDiv}
                    className="artist-thumbnail profile-image"
                  >
                    <button
                      onClick={onButtonClick}
                      type="button"
                      className="btn btn-camera"
                    >
                      <FontAwesomeIcon icon={faCamera} size="2x" />
                    </button>
                  </div>
                  <Button
                    onClick={() => removeProfileImage()}
                    variant="link"
                    className="text-secondary text-decoration-none"
                  >
                    Remove
                  </Button>
                  <input
                    className="d-none"
                    ref={inputRef}
                    type="file"
                    id="profile-image-upload"
                    multiple={false}
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              </Col>
            </Row>

            <hr className="my-4" />

            {userDetails.addressDetailsList &&
              userDetails.addressDetailsList.map((address, i) => {
                return (
                  <Row key={i}>
                    <Col xs={12} md={5} className="mb-3">
                      <div className="d-flex">
                        <h5 className="fw-bold mb-3 text-capitalize">
                          {address.addressType.toLowerCase()} Address
                        </h5>
                        {/* {address.addressType == "BILLING" &&
                                                    <Form.Check 
                                                        type="checkbox"
                                                        name="billingAddressSame"
                                                        label="Same as Principal Address"
                                                        className="ms-4"
                                                        checked={userDetails.billingAddressSame}
                                                        onChange={handleAddressSameCheckboxChange}
                                                    />
                                                } */}
                      </div>

                      <Form.Group controlId="addressLine1" className="mb-3">
                        <Form.Label className="required">Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="address..."
                          value={userDetails.addressDetailsList[i].addressLine1}
                          onChange={(e) => handleAddressChange(e, i)}
                        />
                        {errors &&
                          errors[address.addressType] &&
                          errors[address.addressType].addressLine1 && (
                            <Form.Text className="text-danger">
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className="me-2"
                              />
                              {errors[address.addressType].addressLine1}
                            </Form.Text>
                          )}
                      </Form.Group>
                      <Form.Group controlId="addressLine1" className="mb-3">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="address2..."
                          value={userDetails.addressDetailsList[i].addressLine2}
                          onChange={(e) => handleAddressChange(e, i)}
                        />
                      </Form.Group>
                      <div className="d-flex align-items-center mb-3">
                        <Form.Group
                          controlId="city"
                          className="mb-3 me-4 flex-grow-1"
                        >
                          <Form.Label className="required">City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="city"
                            value={userDetails.addressDetailsList[i].city}
                            onChange={(e) => handleAddressChange(e, i)}
                          />
                          {errors &&
                            errors[address.addressType] &&
                            errors[address.addressType].city && (
                              <Form.Text className="text-danger">
                                <FontAwesomeIcon
                                  icon={faTriangleExclamation}
                                  className="me-2"
                                />
                                {errors[address.addressType].city}
                              </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group
                          controlId="country"
                          className="mb-3 flex-grow-1"
                        >
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="country"
                            value={userDetails.addressDetailsList[i].country}
                            onChange={(e) => handleAddressChange(e, i)}
                          />
                        </Form.Group>
                      </div>
                      <div className="d-flex align-items-center">
                        <Form.Group
                          controlId="postalCode"
                          className="me-4 flex-grow-1"
                        >
                          <Form.Label className="required">
                            Zip/Postal Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="zip/postal code"
                            value={userDetails.addressDetailsList[i].postalCode}
                            onChange={(e) => handleAddressChange(e, i)}
                          />
                          {errors &&
                            errors[address.addressType] &&
                            errors[address.addressType].postalCode && (
                              <Form.Text className="text-danger">
                                <FontAwesomeIcon
                                  icon={faTriangleExclamation}
                                  className="me-2"
                                />
                                {errors[address.addressType].postalCode}
                              </Form.Text>
                            )}
                        </Form.Group>

                        <Form.Group
                          controlId="province"
                          className="flex-grow-1"
                        >
                          <Form.Label>Province:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="province"
                            value={userDetails.addressDetailsList[i].province}
                            onChange={(e) => handleAddressChange(e, i)}
                          />
                          {/* <Form.Select value={userDetails.addressDetailsList[0].province} >
                                                        <option value="">Select a province</option>
                                                        {Object.keys((PROVINCE_ENUM)).map((k, i) => {
                                                            return <option key={i} value={k}>{PROVINCE_ENUM[k]}</option>
                                                        })}
                                                    </Form.Select> */}
                        </Form.Group>
                      </div>
                    </Col>
                    <hr className="my-4" />
                  </Row>
                );
              })}

            {/* <Row>
							<Col xs={12} md={5} className="mb-3">
								<div className="d-flex">
									<h5 className="fw-bold mb-3">Billing Address</h5>
									<Form.Check 
										type="checkbox"
										name="billingAddressSame"
										label="Same as Principal Address"
                                        className="ms-4"
                                        checked={userDetails.billingAddressSame}
                                        onChange={handleAddressSameCheckboxChange}
									/>
								</div>
								
								<Form.Group controlId="addressLine1" className="mb-3"> 
									<Form.Label className="required">Address</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="address..."
                                        value={userDetails.addressDetailsList[userDetails.billingAddressSame ? 0 : 1].addressLine1}
                                        disabled={userDetails.billingAddressSame}
                                        onChange={(e) => handleAddressChange(e, 1)}
									/> 
                                    {errors && errors[ADDRESS_TYPES.BILLING] && errors[ADDRESS_TYPES.BILLING].addressLine1 &&
                                        <Form.Text className="text-danger">
                                            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                            {errors[ADDRESS_TYPES.BILLING].addressLine1}
                                        </Form.Text>
                                    }
								</Form.Group>
								<Form.Group controlId="addressLine2" className="mb-3"> 
									<Form.Label>Address 2</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="address2..."
                                        value={userDetails.addressDetailsList[userDetails.billingAddressSame ? 0 : 1].addressLine2}
                                        disabled={userDetails.billingAddressSame}
                                        onChange={(e) => handleAddressChange(e, 1)}
									/>
								</Form.Group>
								<div className="d-flex align-items-center mb-3">
									<Form.Group controlId="city" className="mb-3 me-4 flex-grow-1"> 
										<Form.Label className="required">City</Form.Label>
										<Form.Control 
											type="text" 
											placeholder="city"
                                            value={userDetails.addressDetailsList[userDetails.billingAddressSame ? 0 : 1].city}
                                            disabled={userDetails.billingAddressSame}
                                            onChange={(e) => handleAddressChange(e, 1)}
										/> 
                                        {errors && errors[ADDRESS_TYPES.BILLING] && errors[ADDRESS_TYPES.BILLING].city &&
                                            <Form.Text className="text-danger">
                                                <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                {errors[ADDRESS_TYPES.BILLING].city}
                                            </Form.Text>
                                        }
									</Form.Group>
									<Form.Group controlId="country" className="mb-3 flex-grow-1"> 
										<Form.Label>Country</Form.Label>
										<Form.Control 
											type="text" 
											placeholder="country"
                                            value={userDetails.addressDetailsList[userDetails.billingAddressSame ? 0 : 1].country}
                                            disabled={userDetails.billingAddressSame}
                                            onChange={(e) => handleAddressChange(e, 1)}
										/> 
									</Form.Group>
								</div>
								<div className="d-flex align-items-center">
									<Form.Group controlId="postalCode" className="me-4 flex-grow-1"> 
										<Form.Label className="required">Zip/Postal Code</Form.Label>
										<Form.Control 
											type="text" 
											placeholder="zip/postal code"
                                            value={userDetails.addressDetailsList[userDetails.billingAddressSame ? 0 : 1].postalCode}
                                            disabled={userDetails.billingAddressSame}
                                            onChange={(e) => handleAddressChange(e, 1)}
										/> 
                                        {errors && errors[ADDRESS_TYPES.BILLING] && errors[ADDRESS_TYPES.BILLING].postalCode &&
                                            <Form.Text className="text-danger">
                                                <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                {errors[ADDRESS_TYPES.BILLING].postalCode}
                                            </Form.Text>
                                        }
									</Form.Group>

									<Form.Group controlId="province" className="flex-grow-1">
										<Form.Label>Province:</Form.Label>
                                        <Form.Control 
											type="text" 
											placeholder="province"
                                            value={userDetails.addressDetailsList[userDetails.billingAddressSame ? 0 : 1].province}
                                            disabled={userDetails.billingAddressSame}
                                            onChange={(e) => handleAddressChange(e, 1)}
										/> 
									
									</Form.Group>
								</div>
							</Col>
						</Row>

						<hr className="my-4" /> */}

            <Row>
              <Col className="d-flex">
                {/* <Button variant="outlined" 
									className="ms-auto me-3 border border-dark">
										Discard
								</Button> */}
                <Button
                  type="submit"
                  variant="orange"
                  className="text-white px-5 ms-auto border border-orange"
                >
                  {isSaving && (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span>Saving...</span>
                    </>
                  )}
                  {!isSaving && <span>Save</span>}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </>
  );
};

export default ContactInfo;
