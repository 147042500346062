import React, { createContext, useContext, useState, useEffect } from 'react';
import i18n from 'i18next';
import ukImg from '../assets/uk-flag.png';
import frImg from '../assets/fr-flag.png';
const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    console.log('change language: ', lng);

    if (lng !== i18n.language) {
      // Only change if different
      i18n.changeLanguage(lng);
    }
  };

  const langFlags = {
    en: { flag: ukImg, lang: 'English' },
    fr: { flag: frImg, lang: 'French' },
  };

  // Listen for language changes in i18n and update state only if different
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      if (lng !== language) {
        setLanguage(lng);
      }
    };

    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, langFlags }}>
      {children}
    </LanguageContext.Provider>
  );
};
