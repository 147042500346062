import React, { useEffect } from 'react';
import { useAuth } from '../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { googleCallbackFetchData } from '../../utils/APIUtils';

export default function OAuth2Redirect() {
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log('code', code);
    const getUser = async () => {
      try {
        const user = await googleCallbackFetchData(code);
        console.log('user', user);
        auth.setUser(user);
        navigate('/events');
      } catch (error) {
        console.error('Failed to fetch user info', error);
        navigate('/login');
      }
    };
    getUser();
  }, []);

  // TODO: Add loading effect in the return
  return <>Loading...</>;
}
