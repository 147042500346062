import React, { useEffect, useState } from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Nav,
  Row,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faCircle,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { getEventDetails } from '../../utils/APIUtils';
import { useAsync } from '../../context/useAsync';
import moment from 'moment';
import ShareEvent from './ShareEvent';
import ToastMessage from '../../shared/ToastMessage';
import Accordion from 'react-bootstrap/Accordion';
import {
  getSignedInUserEmail,
  initClient,
  publishTheCalenderEvent,
  signInToGoogle,
} from './useGoogleService';
import { faTicket } from '@fortawesome/free-solid-svg-icons/faTicket';

/**
 * Represents the Preview event component for the preview event page.
 *
 * @returns {React.FunctionComponent} The PreviewEventWithPerformances component.
 */
const PreviewEventWithPerformances = () => {
  /**
   * State hook to store the signed in state of user
   * @type {React.State<boolean>}
   */
  const [signedin, setSignedIn] = useState(false);

  /**
   * State hook to store the google authorized email
   * @type {React.State<string|null>}
   */
  const [googleAuthedEmail, setgoogleAuthedEmail] = useState(null);

  /**
   * Asynchronously retrieves the authorized Google email of the user when the user is signs with the email.
   * If the user is not authorized, it initiates the authorization process.
   *
   * @return {Promise<void>} A Promise that resolves when the email is retrieved or authorization is initiated.
   */
  const getGoogleAuthorizedEmail = async () => {
    let email = await getSignedInUserEmail();
    if (email) {
      setSignedIn(true);
      setgoogleAuthedEmail(email);
    } else {
      getAuthToGoogle();
    }
  };

  /**
   * Asynchronously signs in the user to Google and retrieves their authorized email.
   *
   * @return {Promise<void>} A Promise that resolves when the user is signed in and their email is retrieved.
   */
  const getAuthToGoogle = async () => {
    let successfull = await signInToGoogle();
    if (successfull) {
      getGoogleAuthorizedEmail();
    }
  };

  /**
   * Function representing how to add an event to a Google calendar.
   *
   * @return {void} This function does not return anything.
   */
  const addEventToGoogleCalendar = () => {
    initClient((success) => {
      if (success) {
        getGoogleAuthorizedEmail();

        // Event variable stote the information of the event like summery, location, start date time, end date time and attendees in json key value pair format
        let event = {
          summary: eventDTO.eventTitle,
          location: window.location.href,
          start: {
            dateTime: moment(
              eventDTO.eventStartDate + ' ' + eventDTO.eventStartTime
            ).toISOString(),
            timeZone: eventDTO.timezone,
          },
          end: {
            dateTime: moment(
              eventDTO.eventEndDate + ' ' + eventDTO.eventEndTime
            ).toISOString(),
            timeZone: eventDTO.timezone,
          },
          // recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
          attendees: [],
          reminders: {
            useDefault: false,
            overrides: [
              { method: 'email', minutes: 24 * 60 },
              { method: 'popup', minutes: 10 },
            ],
          },
        };

        // Function represents the publish and event on the calendar
        publishTheCalenderEvent(event).then((res) => {
          if (res.error) {
            let msg = res.error.message;
            setToast({ show: true, msg: msg, success: false });
          } else if (res.htmlLink) {
            setToast({
              show: true,
              msg: 'Event Successfully added to your calendar',
              success: true,
            });
          } else {
            setToast({
              show: true,
              msg: 'Unable to add event to your calender. Please try after some time',
              success: false,
            });
          }
        });
      }
    });
  };

  /**
   * useAsync is a custom React hook that provides a way to handle asynchronous operations and manage their state.
   * It takes in an optional default data parameter and returns an object with data, error, and loading properties.
   *
   * @param {any} defaultData - The default data to be used.
   * @return {Object} An object with data, error, and loading properties.
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * A hook that provides the state for the toast message and a function to set it.
   *
   * @return {Array} An array containing the toast message (object) and a function to set it (Function).
   * The toast message object has the following properties:
   *   - show (boolean): Indicates whether the toast message should be displayed or not.
   *   - success (boolean): Indicates whether the toast message is a success or error message.
   *   - msg (string): The message to be displayed in the toast message.
   */
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * Extracts the route parameter using the `useParams()` hook.
   *
   * @return {Object} An object containing the route parameter values.
   */
  const params = useParams();

  /**
   * Extracts the URL for the pathname using the useLocation() hook.
   *
   * @return {Object} An object containing the pathname property which represents the URL for the current path.
   * The pathname property has the following structure:
   *   - pathname (string): The URL for the current path.
   */
  const { pathname } = useLocation();

  // Set the search parameters using the useSearchParams() hook
  /**
   * Sets the search parameters using the useSearchParams() hook.
   *
   * @return {Array} An array containing the search parameters (URLSearchParams) and a function to update them (Function).
   * The search parameters (URLSearchParams) object allows you to work with the query string of a URL.
   * It provides methods to get, set, and remove parameters from the URL.
   * The function to update the search parameters is a callback function that takes in a new search parameter string or an object of key-value pairs.
   * It is used to update the search parameters in the URL.
   */
  const [searchParams, setSearchParams] = useSearchParams();

  // Dislplay the searchParams on the console
  console.log('searchParams ', searchParams);

  /**
   * Checks if the current path matches the preview event path.
   *
   * @return {Object|null} An object containing the match result or null if no match is found.
   * The object has the following structure:
   *   - isExact (boolean): Indicates whether the current path matches the preview event path exactly.
   *   - path (string): The matched path.
   *   - url (string): The matched URL.
   *   - params (Object): An object containing the route parameter values.
   */
  const isPreview = matchPath(
    {
      path: '/preview-event/:eventId',
      exact: true,
    },
    pathname
  );

  /**
   * A state hook to hold the event DTO (Data Transfer Object)
   *
   * @type {Array}
   * @return {Array} An array with two elements:
   *  - eventDTO (Object or null): The event DTO object. It holds the data of the event. Initially, it is null.
   *  - setEventDTO (Function): A function to update the event DTO. It takes an event DTO object as a parameter and updates the state of eventDTO.
   */
  const [eventDTO, setEventDTO] = useState(null);

  /**
   * A state hook to hold the show share modal state.
   *
   * @type {Array}
   * @return {Array} An array with two elements:
   *  - showShareModal (boolean): The current state of the show share modal. Initially, it is set to false.
   *  - setShowShareModal (Function): A function to update the state of showShareModal. It takes a boolean value as a parameter and updates the state accordingly.
   */
  const [showShareModal, setShowShareModal] = useState(false);

  /**
   * useEffect() hook used to fetch the event detail and payment method.
   *
   * This hook is triggered when the component mounts.
   * It fetches the event detail from the server and updates the state of the eventDTO.
   *
   * @return {void}
   */
  useEffect(() => {
    // It fetch the event detail and update the state of the event
    run(() => getEventDetails(params.eventId))
      .then((res) => {
        setEventDTO(res.data);
        console.log('loading', loading);
      })
      .then(() => {})
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
    // .finally(handleStripeRedirect)
  }, []);

  /**
   * useEffect hook used to perform side effects when the `loading` state changes.
   *
   * This hook is triggered when the `loading` state changes.
   * It does not perform any specific action and exists to satisfy the requirement of having a useEffect hook in the component.
   *
   * @return {void} This hook does not return anything.
   */
  useEffect(() => {}, [loading]);

  /**
   * Renders the loading spinner when the loading state is true.
   *
   * @return {JSX.Element} A JSX element containing a spinner and a loading message.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="h-100">
      <Container fluid className="bg-header">
        {isPreview && (
          <>
            <Row>
              <Col xs="12">
                <div className="p-4 text-center">
                  <h4>Preview</h4>
                </div>
              </Col>
            </Row>
            /** * State hook to set the show share modal state using the
            `useState()` hook. * * @return {Array} An array containing the show
            share modal state and a function to update it. */ const
            [showShareModal, setShowShareModal] = useState(false);
            <Row className="bg-light">
              <Col xs="12">
                <div className="p-4 text-center">
                  <Button
                    to={'/promoter-panel/events'}
                    as={Link}
                    variant="link"
                    className="text-dark"
                  >
                    <h5>Close Preview</h5>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}

        {/* banner section starts */}
        <Row>
          <Col className="px-0">
            <div
              className="homebanner position-relative"
              style={{
                backgroundImage: `url(/default_event_banner.png)`,
                height: '653px',
              }}
            >
              <div className="banner-overlay d-flex">
                <div
                  id="bannertext"
                  className="banner-text d-flex flex-grow-1 mt-auto p-5 mb-5 mx-4 text-white"
                >
                  <div>
                    <h1 className="fw-bold">{eventDTO.eventTitle}</h1>
                    <div className="d-flex">
                      <Button
                        onClick={addEventToGoogleCalendar}
                        variant="link"
                        className="text-white text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className="me-2"
                          size="lg"
                        />
                        <h5 className="mb-0">Add to calendar</h5>
                      </Button>

                      <span className="h-100 py-3 mx-5 h4 mb-0"> </span>

                      <Button
                        onClick={() => setShowShareModal(true)}
                        variant="link"
                        className="text-white text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faShareAlt}
                          className="me-2"
                          size="lg"
                        />
                        <h5 className="mb-0">Share</h5>
                      </Button>
                    </div>
                    <h4 className="mt-2">
                      <span>By </span>
                      <span className="fw-bold text-decoration-underline">
                        {eventDTO.promoter}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* banner section ends */}
        <Row>
          <div className="p-lg-5 p-3 ">
            <Card className="mb-5 performance-view">
              <Card.Body
                to={'1'}
                as={Link}
                className="performance-view-link p-4"
              >
                <Card.Text>
                  <Col xs="12">
                    <Row className="border-hover-right mb-1 align-items-start">
                      <Col lg="3" xs="6" className="d-flex px-4">
                        <img
                          className="img-fluid"
                          src="/banner.jpg"
                          alt="event thumbnail"
                        />
                      </Col>

                      {/* hide on lg screen */}
                      <Col xs="6" className="d-lg-none d-flex my-auto">
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="fs-5 fw-bold">
                            <FontAwesomeIcon icon={faTicket} /> Price: From $
                            {eventDTO.eventTicketDetailsModel.price
                              ? eventDTO.eventTicketDetailsModel.price
                              : 0}
                          </span>
                        </div>
                      </Col>

                      <Col lg="9" xs="12" className="d-flex mt-4 mt-lg-0">
                        <div className="flex-grow-1">
                          <h3 className="fw-bold">{eventDTO.eventTitle}</h3>
                          <p className="mb-2">
                            <span className="fw-bold">Artist:</span> Sarah
                            Mclachlan
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              size="xs"
                              className="me-2"
                              icon={faCircle}
                            />
                            <span className="fw-bold">
                              Time: 1:00 PM - 3:00 PM
                            </span>
                          </p>
                          <p className="mb-0">
                            <span className="fw-bold">Description: </span>Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </p>
                        </div>

                        <div
                          className="text-center flex-grow-1 col-5 d-none d-lg-flex flex-column"
                          title={eventDTO.eventTitle}
                        >
                          <div className="mt-auto">
                            <span className="fs-5 fw-bold">
                              <FontAwesomeIcon icon={faTicket} /> Price: From $
                              {eventDTO.eventTicketDetailsModel.price
                                ? eventDTO.eventTicketDetailsModel.price
                                : 0}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-5 performance-view">
              <Card.Body
                to={'1'}
                as={Link}
                className="performance-view-link p-4"
              >
                <Card.Text>
                  <Col xs="12">
                    <Row className="border-hover-right mb-1 align-items-start">
                      <Col lg="3" xs="6" className="d-flex px-4">
                        <img
                          className="img-fluid"
                          src="/banner.jpg"
                          alt="event thumbnail"
                        />
                      </Col>

                      {/* hide on lg screen */}
                      <Col xs="6" className="d-lg-none d-flex my-auto">
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="fs-5">
                            <FontAwesomeIcon icon={faTicket} /> Price: From $
                            {eventDTO.eventTicketDetailsModel.price
                              ? eventDTO.eventTicketDetailsModel.price
                              : 0}
                          </span>
                        </div>
                      </Col>

                      <Col lg="9" xs="12" className="d-flex mt-4 mt-lg-0">
                        <div className="flex-grow-1">
                          <h3 className="fw-bold">{eventDTO.eventTitle}</h3>
                          <p className="mb-2">
                            <span className="fw-bold">Artist:</span> Sarah
                            Mclachlan
                          </p>
                          <p className="mb-2">
                            <FontAwesomeIcon
                              size="xs"
                              className="me-2"
                              icon={faCircle}
                            />
                            <span className="fw-bold">
                              Time: 1:00 PM - 3:00 PM
                            </span>
                          </p>
                          <p className="mb-0">
                            <span className="fw-bold">Description: </span>Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </p>
                        </div>

                        <div
                          className="text-center flex-grow-1 col-5 d-none d-lg-flex flex-column"
                          title={eventDTO.eventTitle}
                        >
                          <div className="mt-auto">
                            <span className="fs-5 fw-bold">
                              <FontAwesomeIcon icon={faTicket} /> Price: From $
                              {eventDTO.eventTicketDetailsModel.price
                                ? eventDTO.eventTicketDetailsModel.price
                                : 0}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal
        centered
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareEvent name={eventDTO.eventTitle} url={window.location.href} />
        </Modal.Body>
      </Modal>

      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default PreviewEventWithPerformances;
