import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.scss';
import { navigateToPromoter } from '../../utils/NavigationUtils';
import smallLogo from '../../assets/links-logo.svg';
import linkIcon from '../../assets/link-icon.svg';
import connectIcon from '../../assets/connect-icon.svg';
import shareIcon from '../../assets/share-icon.svg';
import ExplanationGroup from '../../components/ExplanationGroup/ExplanationGroup';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';

/**
 * A React functional component that renders the home page.
 *
 * @return {JSX.Element} The rendered home page.
 */
export default function Home() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const handleTakeATour = () => {
    console.log('handleTakeATour clicked in Home');
    navigate('/contact-us');
  };

  const { t } = useTranslation();

  /**
   * Handles the change in the email input field updating the state.
   *
   * @param {Event} event - The change event object.
   * @return {void} This function does not return anything.
   */
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  /**
   * Navigates to the events page.
   *
   * @return {void} This function does not return anything.
   */
  const navigateToEvents = () => {
    navigate('/events');
  };

  /**
   * Handles the submission of the form to add the email to the mail list.
   *
   * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from actually submitting
    console.log('Form submitted with email:', email);
    // TODO: Add further actions, like sending the email to the backend
    // and displaying a success message or error message
    setEmail('');
  };

  return (
    <div className={styles.home}>
      <section className={styles.banner}>
        <div className={styles.bannerContainer}>
          <h1>{t('home.banner.bannerTitle')}</h1>
          <h4>{t('home.banner.bannerText')}</h4>
          <div className={styles.btnContainer}></div>
          <CustomButton
            clickHandler={handleTakeATour}
            title={t('home.banner.bannerButton')}
            isPill={true}
            colorScheme="white"
          />
        </div>
      </section>
      <section className={styles.promotersAndEvents}>
        <div className={styles.promoters}>
          <div className={styles.promotersContainer}>
            <div className={styles.textContainer}>
              <h1>{t('home.promotersAndEvents.promoters.title')}</h1>
              <h4 className={styles.list}>
                {t('home.promotersAndEvents.promoters.subtitle')}
              </h4>
              <h6>{t('home.promotersAndEvents.promoters.text1')}</h6>
              <h6>{t('home.promotersAndEvents.promoters.text2')}</h6>
            </div>
            <div className={styles.btnContainer}>
              <CustomButton
                clickHandler={navigateToPromoter}
                title={t('home.promotersAndEvents.promoters.promoterButton')}
                isPill={true}
                colorScheme="black"
              />
            </div>
          </div>
        </div>
        <div className={styles.logoContainer}>
          <img
            src={smallLogo}
            alt="Eventlinx Logo"
            className={styles.linksLogo}
          />
        </div>
        <div className={styles.events}>
          <div className={styles.eventsContainer}>
            <div className={styles.textContainer}>
              <h1>{t('home.promotersAndEvents.events.title')}</h1>
              <h4 className={styles.list}>
                {t('home.promotersAndEvents.events.subtitle')}
              </h4>
              <h6>{t('home.promotersAndEvents.events.text1')}</h6>
              <h6>{t('home.promotersAndEvents.events.text2')}</h6>
            </div>
            <div className={styles.btnContainer}>
              <CustomButton
                clickHandler={navigateToEvents}
                title={t('home.promotersAndEvents.events.eventButton')}
                isPill={true}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.whyEventlinx}>
        <div className={styles.explanations}>
          <ExplanationGroup
            icon={linkIcon}
            title={t('home.whyEventlinx.group1.title')}
            altTxt={t('home.whyEventlinx.group1.altText')}
            normalText={t('home.whyEventlinx.group1.normalText.text1')}
            boldText={t('home.whyEventlinx.group1.boldText')}
          />
          <ExplanationGroup
            icon={shareIcon}
            title={t('home.whyEventlinx.group2.title')}
            altTxt={t('home.whyEventlinx.group2.altText')}
            normalText={[
              t('home.whyEventlinx.group2.normalText.text1'),
              t('home.whyEventlinx.group2.normalText.text2'),
            ]}
            boldText={t('home.whyEventlinx.group2.boldText')}
          />
          <ExplanationGroup
            icon={connectIcon}
            title={t('home.whyEventlinx.group3.title')}
            altTxt={t('home.whyEventlinx.group3.altText')}
            normalText={t('home.whyEventlinx.group3.normalText.text1')}
            boldText={t('home.whyEventlinx.group3.boldText')}
          />
        </div>
      </section>

      <section className={styles.phrase}>
        <h2 className={styles.quote}>{t('home.phrase.quote')}</h2>
        <h2>{t('home.phrase.text')}</h2>
      </section>

      <section className={styles.mailListContainer}>
        <h2>{t('home.mailList.invite')}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            id="email"
            placeholder={t('home.mailList.placeholder')}
            value={email}
            onChange={handleEmailChange}
          />
          <div className={styles.btnContainer}>
            <CustomButton
              type={'submit'}
              title={t('home.mailList.mailListBtn')}
            />
          </div>
        </form>
      </section>
    </div>
  );
}
