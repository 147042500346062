import { Link, useNavigate } from 'react-router-dom';
import { navigateToPromoter } from '../../utils/NavigationUtils';
import styles from './HeaderModalContent.module.scss';
import { useModal } from '../../context/useModal';

export default function HeaderModalContent() {
  const ModalButton = ({ clickHandler, label }) => {
    return (
      <button onClick={clickHandler} className={styles.modalButton}>
        {label}
      </button>
    );
  };

  const { closeModal } = useModal();
  const navigate = useNavigate();
  const handlePatronClick = (e) => {
    e.preventDefault();
    navigate('/register');
    closeModal();
  };

  const handlePromoterClick = () => {
    navigateToPromoter();
    closeModal();
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    navigate('/login');
    closeModal();
  };
  return (
    <div className={styles.modalContent}>
      <h2>SIGN UP</h2>
      <h6> Welcome to EventLinx</h6>
      <div className={styles.btnsContainer}>
        <ModalButton
          clickHandler={(e) => handlePatronClick(e)}
          label="I'm a Viewer"
        />
        <ModalButton
          clickHandler={handlePromoterClick}
          label="I'm a Producer"
        />
      </div>
      <p>
        Already have an account?
        <button onClick={handleLoginClick} className={styles.loginLink}>
          Log In
        </button>
      </p>
    </div>
  );
}
