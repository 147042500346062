/**
 * Single camera component
 * Earlier 2 cameras were minimum required to render components,
 * addition of this component allows promoter to have event streamed
 * with 1 component as well.
 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faCircleArrowUp,
  faHeart,
  faLocationDot,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import { ACCESS_TOKEN } from '../../constants';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { useAsync } from '../../context/useAsync';
import { getCameraDetails } from '../../utils/APIUtils';

/**
 * A constant URL for the static video feed.
 * @type {string}
 * @constant
 */
const STATIC_URL =
  'https://82934cf9c8696bd2.mediapackage.us-east-1.amazonaws.com/out/v1/0bb6caa45a1545799285b4556fbc0972/index.m3u8';

// React functional component renders an html elements for live stream single camera module.
/**
 * React functional component renders an html elements for live stream single camera module.
 * @return {JSX.Element} Returns the JSX elements for the live stream single camera module.
 */
function LiveStreamSingleCamera() {
  /**
   * A React hook that provides access to the route parameters.
   * @type {Object}
   * @constant
   */
  const params = useParams();

  /**
   * A React hook that provides access to router functions.
   * @type {Function}
   * @constant
   */
  const navigate = useNavigate();

  /**
   * A React state hook that holds the secondary source.
   * @type {Object|null}
   */
  const [secondarySource, setSecondarySource] = useState(null);

  /**
   * A React state hook that holds the primary source.
   * @type {Object|null}
   */
  const [primarySource, setPrimarySource] = useState(null);

  /**
   * A React state hook that holds the cameras DTO.
   * @type {Object|null}
   */
  const [camerasDTO, setCamerasDTO] = useState(null);

  /**
   * A destructuring assignment that extracts properties from the useAsync hook.
   * @type {Object}
   * @property {Object|null} data - The data returned from the useAsync hook.
   * @property {boolean} loading - A flag indicating if the data is still loading.
   * @property {Error} error - The error object if an error occurred.
   * @property {Function} run - A function to run the useAsync hook.
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * A React effect hook that runs once after the component is rendered. It
   * calls the `loadCurrentlyLoggedInUser` function and then runs the `run`
   * function with the `getCameraDetails` function. The `run` function is
   * called with the `params.eventId` as an argument.
   *
   * @return {void}
   */
  useEffect(() => {
    // Checks if promoter logged in
    loadCurrentlyLoggedInUser();

    run(() => getCameraDetails(params.eventId))
      .then((res) => {
        console.log(res.data);
        // res.data.cameraDetailsDtoList =  res.data.cameraDetailsDtoList.map((item) => {
        //   item.outputUrl = STATIC_URL;
        //   return item
        // })
        setCamerasDTO(res.data);
        setPrimarySource(res.data.cameraDetailsDtoList[0]);
        if (!res.data.cameraDetailsDtoList[1]) {
          console.log('Second Camera Does Not exist');
          // DO NOT SET SECOND CAMERA.
        }
        setSecondarySource(res.data.cameraDetailsDtoList[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * A function that checks if the user is logged in and has a token.
   * If the user is not logged in or does not have a token, it navigates
   * the user to the login page.
   *
   * @return {void}
   */
  const loadCurrentlyLoggedInUser = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);

    if (!token) {
      navigate('/login');
    }
  };

  // Not needed in this..
  const changeSecondarySource = (camera) => {
    setSecondarySource(camera);
  };

  /**
   * Renders a loading spinner if the loading state is true.
   *
   * @return {JSX.Element|null} The loading spinner or null if loading is false.
   */

  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  // return single camera with live stream..
  return (
    <div className="h-100">
      <Container className="py-5">
        <Row>
          <Col className="pe-lg-1 d-flex flex-column">
            <div className="d-flex justify-content-center align-items-center pb-4">
              <h5 className="fw-bold align-items-center">Paradox</h5>
            </div>
            <div
              className="wrapper"
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              {/* Adjust the width and height here to make the video player larger */}
              <VideoPlayer
                muted={false}
                source={primarySource.outputUrl}
                height={'100%'}
                width={'900vh'}
              />
            </div>
            <br></br>
            <div className="d-flex">
              <h6
                dangerouslySetInnerHTML={{
                  __html: camerasDTO.cameraDetailsDtoList[0].cameraName,
                }}
                className="fw-bold text-primary"
              ></h6>
            </div>
            <div className="decription fs-12">
              <p
                dangerouslySetInnerHTML={{
                  __html: camerasDTO.cameraDetailsDtoList[0].description,
                }}
                className="mb-0 text-break"
              ></p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LiveStreamSingleCamera;
