import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import axios from 'axios';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en', 'fr'],
    ns: ['api'],
    defaultNS: 'api',
    backend: {
      loadPath: `${process.env.REACT_APP_PATRON_API}/content/translations/{{lng}}/api`,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
