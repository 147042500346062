import styles from './AuthenticationHead.module.scss';
import { useNavigate } from 'react-router-dom';

export default function AuthenticationHead({ isLogin }) {
  const navigate = useNavigate();

  return (
    <>
      <h1>{isLogin ? 'Log in' : 'Sign up'}</h1>
      <p>
        {isLogin ? 'Not registered with us yet? ' : 'Already have an account? '}
        <button
          type="button"
          onClick={() => navigate(isLogin ? '/register' : '/login')}
        >
          {isLogin ? 'Sign up' : 'Log in'}
        </button>
      </p>
    </>
  );
}
