import React from 'react';

import styles from './FAQ.module.scss';
import Accordion from '../../components/Accordion/Accordion';

/**
 * @function FAQ
 * @description A React functional component that renders the frequently asked questions (FAQ) section of the website.
 * @returns {JSX.Element} The JSX element that represents the FAQ section of the website.
 */
const FAQ = () => {
  const accordionObjs = [
    {
      number: '01',
      title: 'Is there a fee to use EventLinx?',
      content:
        "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
    },
    {
      number: '02',
      title: 'How do I create an account?',
      content:
        "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
    },
    {
      number: '03',
      title: 'How do I create an event?',
      content:
        "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
    },
    {
      number: '04',
      title: 'Can I edit my event after it’s been published?',
      content:
        "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
    },
    {
      number: '05',
      title: 'Can I get a refund if I can’t attend an event?',
      content:
        "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
    },

    // {number: '05', title: 'Can I use EventLinx on my tablet?', content: 'Yes. EventLinx is compatible with all tablet browsers and devices.'}
  ];
  return (
    <div className={styles.faq}>
      <div className={styles.faqContainer}>
        <h1>
          FREQUENTLY ASKED <span>QUESTIONS</span>
        </h1>
        <div className={styles.accordionContainer}>
          {accordionObjs.map((accordion) => {
            return <Accordion key={accordion.number} {...accordion} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
