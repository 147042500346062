import { useEffect } from 'react';

/**
 * Hook that takes a ref to the element you want to monitor and a callback function
 * to execute when a click outside this element is detected.
 *
 * @param {React.MutableRefObject<HTMLElement | null>} ref - The ref to the element you want to monitor.
 * @param {function} callback - The callback function to execute when a click outside this element is detected.
 */
const useOutsideClick = (ref, callback) => {
  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
