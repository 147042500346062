import styles from './ExplanationGroup.module.scss';

/**
 * React functional component that renders a group with an icon, title, and text.
 *
 * @param {Object} props - The properties object containing the following properties:
 *   @property {string} icon - The icon to use for the group.
 *   @property {string} altTxt - The alt text for the icon.
 *   @property {string} title - The title of the explanation group.
 *   @property {string|string[]} normalText - The text to display in the explanation group.
 *   @property {string|string[]} boldText - The bold text to display in the explanation group.
 * @return {JSX.Element} The JSX element representing the explanation group.
 */
export default function ExplanationGroup({
  icon,
  altTxt,
  title,
  normalText,
  boldText,
}) {
  return (
    <div className={styles.explanationGroup}>
      <img src={icon} alt={altTxt} />
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.txtContainer}>
        {typeof normalText === 'string' ? (
          <p>{normalText}</p>
        ) : (
          normalText.map((t, i) => <p key={`line-${i + 1}`}>{t}</p>)
        )}
        <p className={styles.boldText}>{boldText}</p>
      </div>
    </div>
  );
}
