import { EMAIL_REGEX } from '../constants';

/**
 * Validates the register form input fields.
 *
 * @param {boolean} newUser - Indicates if the user is signing up.
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @param {string} captcha - The captcha response.
 * @param {string} confirmPassword - The confirmation password of the user (optional).
 * @param {string} firstName - The first name of the user (optional).
 * @param {string} lastName - The last name of the user (optional).
 * @return {Object} An object containing validation errors.
 */
const validate = ({
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
}) => {
  let errors = {};

  if (!firstName || !firstName.trim()) {
    errors.firstName = 'First name is required';
  }

  if (!lastName || !lastName.trim()) {
    errors.lastName = 'Last name is required';
  }

  const emailError = validateEmail(email);
  if (emailError) {
    errors.email = emailError;
  }

  // if (!captcha.trim()) {
  //   errors.captcha = 'Captcha is required';
  // }

  const passwordErrors = validatePassword(password, confirmPassword);
  errors = { ...errors, ...passwordErrors };

  return errors;
};

/**
 * Validates the password fields.
 *
 * @param {string} password - The password to be validated.
 * @param {string} confirmPassword - The confirmation password to be validated.
 * @return {Object} An object containing validation errors for the password and confirmPassword fields.
 */
// const validatePassword = (password, confirmPassword) => {
//   const PSWRD_REGEX =
//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
//   let errors = {};

//   if (!password.trim()) {
//     errors.password = 'Password is required';
//   }

//   if (password && !PSWRD_REGEX.test(String(password))) {
//     errors.password =
//       'A password must be 8 characters in length and contain a number, an uppercase and a special character';
//   }

//   if (confirmPassword !== undefined) {
//     // this block will only run for sign up / reset password
//     if (!confirmPassword.trim()) {
//       errors.confirmPassword = 'Please re-enter password';
//     }

//     if (!(confirmPassword.trim() === password.trim())) {
//       errors.confirmPassword = 'Please make sure your passwords match';
//     }
//   }

//   return errors;
// };

const validatePassword = (password, confirmPassword) => {
  const PSWRD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  let errors = {};

  if (!password || !password.trim()) {
    errors.password = 'Password is required';
  } else if (!PSWRD_REGEX.test(password)) {
    errors.password =
      'Password must be at least 8 characters and include one uppercase letter, one lowercase letter, one number, and one special character';
  }

  if (confirmPassword !== undefined) {
    if (!confirmPassword || !confirmPassword.trim()) {
      errors.confirmPassword = 'Confirmation password is required';
    } else if (password.trim() && confirmPassword.trim() !== password.trim()) {
      errors.confirmPassword = 'Passwords do not match';
    }
  }

  return errors;
};

/**
 * Validates the login email.
 *
 * @param {string} email - The email to be validated.
 * @return {string} An error message if the email is invalid, otherwise an empty string.
 */
const validateEmail = (email) => {
  if (!email || !email.trim()) {
    return 'Email is required';
  } else if (!EMAIL_REGEX.test(email.trim())) {
    return 'Enter a valid email address';
  }
  return '';
};

/**
 * Validates the login form.
 *
 * @param {string} email - The email to be validated.
 * @param {string} password - The password to be validated.
 * @return {Object} An object with the error messages.
 */
const loginValidation = (email, password) => {
  let errors = {};
  if (!email || !email.trim()) {
    errors.email = 'Email is required';
  }

  if (!password || !password.trim()) {
    errors.password = 'Password is required';
  }

  return errors;
};

export { validate, validateEmail, validatePassword, loginValidation };
