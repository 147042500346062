/**
 * LiveStream events components
 * Originally, only 2 cameras were supported, but now supports 1 camera as well.
 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faCircleArrowUp,
  faHeart,
  faLocationDot,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import { ACCESS_TOKEN } from '../../constants';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { useAsync } from '../../context/useAsync';
import { getCameraDetails } from '../../utils/APIUtils';
import LiveStreamSingleCamera from './LiveStreamSingleCamera';

// Legacy, to test..
const STATIC_URL =
  'https://82934cf9c8696bd2.mediapackage.us-east-1.amazonaws.com/out/v1/0bb6caa45a1545799285b4556fbc0972/index.m3u8';

// React functional component to render an html elements for live stream module.
/**
 * React functional component to render an html elements for live stream module.
 *
 * @returns {JSX.Element} The rendered component.
 */
function LiveStream() {
  /**
   * React hook to get the route parameters.
   *
   * @return {Object} An object containing the parameters from the route.
   */
  const params = useParams();

  // // hard coded , this data will be recieved from api on page load
  // const streams = [{
  //     type: "producer",
  //     url: "https://d34oinpaa48zht.cloudfront.net/out/v1/0bb6caa45a1545799285b4556fbc0972/index.m3u8",
  //   },
  //   {
  //     type: "drummer",
  //     url: "https://c75a7e79204e539d.mediapackage.us-east-1.amazonaws.com/out/v1/d91666e56eec4910a4a784cd6716e67c/index.m3u8",
  //   },
  //   {
  //     type: "guitarist",
  //     url: "https://c75a7e79204e539d.mediapackage.us-east-1.amazonaws.com/out/v1/d8e972cc80a64f248de5191a21b6a0dd/index.m3u8",
  //   }
  // ]

  /**
   * Router initialized.
   * @type {Function}
   */
  const navigate = useNavigate();

  /**
   * State hook to manage the secondary sources.
   * @type {Function}
   */
  const [secondarySource, setSecondarySource] = useState(null);

  /**
   * State hook to manage the primary sources.
   * @type {Function}
   */
  const [primarySource, setPrimarySource] = useState(null);

  /**
   * State hook to manage the camera DTO.
   * @type {Function}
   */
  const [camerasDTO, setCamerasDTO] = useState(null);

  /**
   * Destructuring assignment to extract properties from the useAsync hook.
   * @type {Object}
   * @property {any} data - The data returned by the useAsync hook.
   * @property {boolean} loading - Indicates if the data is being loaded.
   * @property {Error} error - The error object if there was an error while loading the data.
   * @property {Function} run - The function to run the useAsync hook.
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * Effect hook to handle the primary and secondary sources for cameras.
   *
   * @return {void} No return value.
   */
  useEffect(() => {
    loadCurrentlyLoggedInUser();

    run(() => getCameraDetails(params.eventId))
      .then((res) => {
        console.log(res.data);
        // res.data.cameraDetailsDtoList =  res.data.cameraDetailsDtoList.map((item) => {
        //   item.outputUrl = STATIC_URL;
        //   return item
        // })
        setCamerasDTO(res.data);
        setPrimarySource(res.data.cameraDetailsDtoList[0]);
        if (!res.data.cameraDetailsDtoList[1]) {
          console.log(
            '===================Second Camera Does Not exist===================='
          );
        }
        setSecondarySource(res.data.cameraDetailsDtoList[1]);
        console.log(
          '*********************************Secondary source: ' +
            secondarySource
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Routes to login if promoter not logged in..
  /**
   * Function to load the currently logged in user.
   *
   * @return {void} No return value.
   */
  const loadCurrentlyLoggedInUser = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);

    if (!token) {
      navigate('/login');
    }
  };

  /**
   * Function to change the secondary source.
   *
   * @param {Object} camera - The camera object to set as the secondary source.
   * @return {void} This function does not return anything.
   */
  const changeSecondarySource = (camera) => {
    setSecondarySource(camera);
  };

  /**
   * Renders a loading spinner if the data is still being loaded.
   *
   * @return {JSX.Element} The loading spinner component.
   */
  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="h-100">
      {/* Checks if only 1 camera is added by promoter then single camera component is rendered */}
      {secondarySource ? null : <LiveStreamSingleCamera />}
      {/* Checks if more than 1 then this component is rendered */}
      {secondarySource ? (
        <Container className="py-5">
          <Row>
            <Col xs={12} lg={6} className="pe-lg-5">
              <div className="d-flex align-items-center">
                <h5 className="fw-bold">Paradox</h5>
              </div>
              <div className="wrapper">
                <VideoPlayer
                  muted={false}
                  source={primarySource.outputUrl}
                  height={'300'}
                  width={'100%'}
                ></VideoPlayer>
              </div>
              <div className="d-flex align-items-center">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: camerasDTO.cameraDetailsDtoList[0].cameraName,
                  }}
                  className="fw-bold text-primary"
                ></h6>
              </div>
              <div className="decription fs-12">
                <p
                  dangerouslySetInnerHTML={{
                    __html: camerasDTO.cameraDetailsDtoList[0].description,
                  }}
                  className="mb-0 text-break"
                ></p>
              </div>
            </Col>
            <Col xs={12} lg={6} className="ps-lg-5">
              <div className="d-flex align-items-center">
                <h5 className="fw-bold">&nbsp;</h5>
              </div>
              <div className="wrapper">
                <VideoPlayer
                  muted={true}
                  source={secondarySource.outputUrl}
                  height={'300'}
                  width={'100%'}
                ></VideoPlayer>
              </div>
              <div className="d-flex align-items-center">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: secondarySource.cameraName,
                  }}
                  className="fw-bold text-secondary"
                ></h6>
              </div>
              <div className="decription fs-12">
                <p
                  dangerouslySetInnerHTML={{
                    __html: secondarySource.description,
                  }}
                  className="mb-0 text-break"
                ></p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="px-4">
              <h5 className="fw-bold">
                {camerasDTO.totalCameras - 1} Camera(s)
              </h5>
              <Row className="cameras-wrapper border border-gray rounded">
                <Col>
                  <Row>
                    <Col
                      xs={12}
                      lg={6}
                      className="d-flex p-4 flex-wrap shadow-left shadow-lg-show"
                    >
                      {camerasDTO &&
                        camerasDTO.cameraDetailsDtoList
                          .slice(1)
                          .map((item, i) => {
                            return (
                              <div key={i} className="camera-item px-2 mb-3">
                                <Button
                                  variant="link"
                                  className="position-relative p-0"
                                  onClick={() => changeSecondarySource(item)}
                                >
                                  <img
                                    src={item.thumbnailUrl}
                                    alt="poster"
                                    width="100"
                                    height="70"
                                  />
                                </Button>
                                <p className="mb-0 fs-10 mt-1 text-center">
                                  {item.cameraName}
                                </p>
                              </div>
                            );
                          })}

                      {/* <div className='camera-item px-2 mb-3'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/>
                                  <span className='vip-pass bg-info text-white'>VIP</span>
                                </Button>
                                <p className='mb-0 fs-10 mt-1'>Lead Guitar</p>
                            </div>

                            <div className='camera-item px-2 mb-3'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/> 
                                </Button>
                                <p className='mb-0 fs-10 mt-1'>Lead Vocal</p>
                            </div>

                            <div className='camera-item px-2 mb-3'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/> 
                                </Button>
                                <p className=' mb-0 fs-10'>Side Stage</p>
                            </div>

                            <div className='camera-item px-2'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/> 
                                </Button>
                                <p className=' mb-0 fs-10'>Band / Left</p>
                            </div>

                            <div className='camera-item px-2'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/> 
                                </Button>
                                <p className=' mb-0 fs-10'>Band / Left</p>
                            </div>

                            <div className='camera-item px-2'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/> 
                                </Button>
                                <p className=' mb-0 fs-10'>Band / Left</p>
                            </div>

                            <div className='camera-item px-2'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/> 
                                </Button>
                                <p className=' mb-0 fs-10'>Band / Left</p>
                            </div>

                            <div className='camera-item px-2'>
                                <Button variant='link' className='position-relative p-0'>
                                  <img src='/poster2.png' alt='poster' width='100' height='50'/> 
                                </Button>
                                <p className=' mb-0 fs-10'>Band / Left</p>
                            </div>   */}
                    </Col>
                    <Col xs={12} lg={6} className="p-5">
                      <div className="d-flex align-items-center">
                        <Button
                          variant="link"
                          className="text-secondary text-decoration-none fw-bold px-0"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon={faCircleArrowUp}
                            size="lg"
                          />
                          Show Camera
                        </Button>
                        <span className="fw-bold ms-2">
                          {secondarySource.cameraName}
                        </span>
                        {/* <span className='ms-auto fs-12'>2345 views</span> */}
                      </div>
                      <div className="decription fs-12">
                        <p
                          className="mb-0 text-break"
                          dangerouslySetInnerHTML={{
                            __html: secondarySource.description,
                          }}
                        ></p>
                        {/* <p className='mb-1'>Pandora's Box was a female pop group, assembled by Jim Steinman in the 1980s. 
                                    Some of its members had previously worked with Steinman, in the ensemble Fire Inc., on the 
                                    album Bat Out of Hell, on live shows and on other studio recordings
                              </p>  */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : null}
    </div>
  );
}

export default LiveStream;
